import axios from "axios";
import { v4 } from "uuid";
import { Assistant } from "../models/Assistant";

export async function getAssistants(accessToken: string): Promise<Assistant[]> {
    const response = await axios.get(process.env.REACT_APP_API_URL + '/v1/assistants', {
        headers: {
            'Authorization': accessToken,
            'X-Request-ID': v4()
        }
    });

    if (response.status !== 200) {
        throw new Error('Failed to get assistants');
    }

    return response.data as Assistant[];
}