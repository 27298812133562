import axios from "axios";
import { v4 } from "uuid";

export const getQuestionSuggestions = async (ticker: string, accessToken: string): Promise<string[]> => {
    const response = await axios.get(process.env.REACT_APP_API_URL + `/v1/suggestions/questions/${ticker}`, {
        headers: {
            'Authorization': accessToken,
            'X-Request-ID': v4()
        }
    });

    if (response.status !== 200) {
        throw new Error('Failed to get question suggestions');
    }

    return response.data as string[];
}

export const getJSONSchemaSuggestion = async (accessToken: string, prompt: string): Promise<string> => {
    try {
        const response = await axios.post(process.env.REACT_APP_API_URL + '/v1/suggestions/jsonschema', {
            prompt
        }, {
            headers: {
                'Authorization': accessToken,
                'X-Request-ID': v4()
            }
        });
    
        if (response.status !== 200) {
            throw new Error('Failed to get JSON schema suggestion');
        }
    
        return JSON.stringify(response.data, null, 4);   
    }catch(e : any) {
        if (e.response.data) {
            throw new Error(e.response.data.message);
        }

        throw new Error('Failed to get JSON schema suggestion');
    }
}
