import { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { setNotification } from '../store/notifications-reducer';

const NotificationPopup = () => {

    const notification = useAppSelector((state) => state.notifications.currentNotification);

    const [showNotification, setShowNotification] = useState(false);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!notification) {
            return;
        }
        setShowNotification(true);

        // Hide the notification after 5 seconds
        const hideTimer = setTimeout(() => {
            setShowNotification(false);
            dispatch(setNotification(undefined));
        }, 7000);

        // Clean up the timer
        return () => {
            clearTimeout(hideTimer);
        };
    }, [notification]);

    const getNotificationBg = () => {
        if (!notification) {
            return '';
        }

        switch (notification.type) {
            case 'success':
                return 'bg-green-500';
            case 'error':
                return 'bg-red-500';
            case 'info':
                return 'bg-orange-500';
            default:
                return '';
        }
    }
    if (!notification || !showNotification) {
        return <></>
    }
    return (
        <div className="flex items-center justify-center">
            <div className={`fixed ${getNotificationBg()} top-0 w-25 z-50 text-white px-4 py-2 rounded shadow-lg`} style={{ marginTop: '2vh' }}>
                <div className="flex items-center justify-between">
                    <span>{notification?.message}</span>
                    <button
                        className="ml-4 text-white hover:text-gray-200"
                        onClick={() => setShowNotification(false)}
                    >
                        &times;
                    </button>
                </div>
            </div>
        </div>
    );
};

export default NotificationPopup;
