import { useState, useEffect, useRef } from "react";

const SendMessageInputWithSuggestions = ({ suggestions, setMessage, sendMessage }: { suggestions: string[], setMessage: (val: string) => void, sendMessage: () => Promise<void> }) => {
    const [currentSuggestionIndex, setCurrentSuggestionIndex] = useState(0);
    const [inputValue, setInputValue] = useState("");
    const inputRef = useRef<any>(null);

    // Update the suggestion index periodically
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentSuggestionIndex((prevIndex) => (prevIndex + 1) % suggestions.length);
        }, 3000); // Update every 3 seconds

        return () => clearInterval(interval);
    }, [suggestions.length]);

    // Handle user input
    const handleInputChange = (event: any) => {
        setInputValue(event.target.value);
        setMessage(event.target.value);
    };

    // Handle Tab key to use the suggestion
    const handleKeyDown = (event: any) => {
        if (event.key === "Tab") {
            event.preventDefault(); // Prevent default Tab behavior
            setInputValue(suggestions[currentSuggestionIndex]);
            setMessage(suggestions[currentSuggestionIndex]);
            if (inputRef.current) {
                inputRef.current.focus();
            }
        } else if (event.key === 'Enter') {
            sendMessage();
            setInputValue('');
            setMessage('');
        }
    };

    return (
        <div className="relative">
            <input
                id="send-message-input"
                type="text"
                className="w-full border-2 rounded-lg p-2 pr-16" // Added pr-16 to create space for the button
                value={inputValue}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                placeholder={suggestions[currentSuggestionIndex]}
                ref={inputRef}
                data-hj-allow
            />
            <button
                disabled={false}
                onClick={() => {
                    console.log('xxx')
                    sendMessage()
                    setInputValue('');
                    setMessage('');
                }}
                className="absolute right-2 top-2 text-blue-500 hover:underline"
            >
                Send
            </button>
        </div>
    );
};

export default SendMessageInputWithSuggestions;
