import { useState, useEffect } from 'react';
import { useAppDispatch } from '../store/hooks';
import { Transcript } from '../api/models/Transcript';
import { EarningsDate } from '../api/models/EarningsDate';
import { getEarningsDates } from '../api/thunks/financials';
import { getEarningsTranscript } from '../api/thunks/transcripts';
import { setNotification } from '../store/notifications-reducer';
import { useLocation } from 'react-router-dom';

export const useTranscript = (
  supportedTickers: string[],
  accessToken: string | undefined,
  isShareMode: boolean
) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  
  // Initialize with first supported ticker or empty string
  const [currentTicker, setCurrentTicker] = useState<string>(
    supportedTickers?.length > 0 ? supportedTickers[0] : ''
  );
  const [transcript, setTranscript] = useState<Transcript | undefined>(undefined);
  const [fiscalDateEnding, setFiscalDateEnding] = useState<string>('');
  const [dates, setDates] = useState<EarningsDate[]>([]);
  
  // Update ticker if supportedTickers changes and current isn't in the list
  useEffect(() => {
    if (supportedTickers.length > 0 && !supportedTickers.includes(currentTicker)) {
      setCurrentTicker(supportedTickers[0]);
    }
  }, [supportedTickers]);
  
  // Load dates when ticker changes
  useEffect(() => {
    if (!currentTicker || (!accessToken && !isShareMode)) return;
    
    const loadTranscriptDates = async () => {
      try {
        const data = await getEarningsDates(
          { ticker: currentTicker, entityType: 'transcript' }, 
          accessToken || location.search.split('share=')[1]
        );
        
        setDates(data.dates);
        
        // If we have dates, use the most recent one with access
        if (data.dates.length > 0) {
          const mostRecentWithAccess = data.dates.find(date => date.userHasAccess);
          if (mostRecentWithAccess) {
            // When ticker changes, always update the fiscal date to match the new ticker's latest date
            setFiscalDateEnding(mostRecentWithAccess.date);
          }
        } else {
          // Clear the fiscal date if no dates are available
          setFiscalDateEnding('');
        }
      } catch (error) {
        console.error(error);
        dispatch(setNotification({
          message: 'Failed to get transcript dates',
          type: 'error'
        }));
      }
    };
    
    loadTranscriptDates();
  }, [currentTicker, accessToken]);
  
  // Load transcript only after we have both ticker and fiscalDateEnding
  useEffect(() => {
    if (!currentTicker || !fiscalDateEnding || (!accessToken && !isShareMode)) return;
    
    const loadTranscript = async () => {
      try {
        const data = await getEarningsTranscript(
          currentTicker, 
          fiscalDateEnding,
          accessToken || location.search.split('share=')[1],
          dispatch
        );
        
        setTranscript(data);
      } catch (error) {
        dispatch(setNotification({
          message: 'Failed to get transcript',
          type: 'error'
        }));
      }
    };
    
    loadTranscript();
  }, [fiscalDateEnding, accessToken, isShareMode]);
  
  // Custom function to handle ticker change
  const handleSetCurrentTicker = (newTicker: string) => {
    if (newTicker !== currentTicker) {
      setCurrentTicker(newTicker);
      // We don't set fiscalDateEnding here - that will happen in the dates useEffect
    }
  };
  
  // Custom function to handle date change
  const handleSetCurrentFiscalDateEnding = (date: string) => {
    setFiscalDateEnding(date);
  };
  
  return {
    transcript,
    fiscalDateEnding,
    dates,
    currentTicker,
    setCurrentTicker: handleSetCurrentTicker,
    setCurrentFiscalDateEnding: handleSetCurrentFiscalDateEnding
  };
};