import React, { useState } from 'react';
import { ToolbarComponent } from './toolbar-component';

interface Stats {
    workflowsExecuted: number;
    conversations: number;
    messagesSent: number;
    artifacts: number;
    logs: string[];
}

const Dashboard: React.FC = () => {
    const [stats, setStats] = useState<Stats>({
        workflowsExecuted: 120,
        conversations: 75,
        messagesSent: 250,
        artifacts: 40,
        logs: [
            'Workflow A executed at 10:00 AM',
            'Workflow B executed at 11:00 AM',
            'Workflow C executed at 12:00 PM',
            'Workflow D executed at 01:00 PM',

            'Workflow A executed at 10:00 AM',
            'Workflow B executed at 11:00 AM',
            'Workflow C executed at 12:00 PM',
            'Workflow D executed at 01:00 PM',
        ],
    });

    return (
        <div className="flex flex-col h-screen">
            <div className='flex-grow p-8 overflow-y-auto'>
                <h1 className="text-4xl font-bold text-primary-900 mb-6">Dashboard</h1>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                    {Object.keys(stats).slice(0, 4).map((key) => (
                        <div
                            key={key}
                            className="p-6 shadow-lg rounded-lg bg-white border border-neutral-300"
                        >
                            <h2 className="text-lg font-medium text-neutral-800">
                                {key.replace(/([A-Z])/g, ' $1').replace(/^\w/, c => c.toUpperCase())}
                            </h2>
                            <p className="text-3xl font-bold text-primary-600">
                                {(stats as any)[key]}
                            </p>
                        </div>
                    ))}
                </div>
                <div className="mt-8">
                    <h2 className="text-2xl font-semibold text-primary-900 mb-4">
                        Workflow Run Logs
                    </h2>
                    <div className="bg-white shadow-lg rounded-lg border border-neutral-300">
                        <ul className="max-h-64 overflow-y-auto p-6">
                            {stats.logs.map((log, index) => (
                                <li
                                    key={index}
                                    className="py-2 text-neutral-700 border-b last:border-b-0"
                                >
                                    {log}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
            <div className="flex-shrink-0">
                <ToolbarComponent
                    handleUpdateConversation={() => { }}
                    convo={undefined}
                    convos={[]}
                    handleConversationClick={(id) => { alert('not implemented') }}
                    handleCreateConversation={() => { }}
                    handleDeleteConversation={async (id: string) => { }}
                    supportedTickers={[]}
                    handleDeleteShareLink={(id: string) => { }}
                />
            </div>
        </div>
    );
};

export default Dashboard;