import { motion } from "framer-motion";

export default function TypingDots() {
  return (
    <div className="flex space-x-1 text-2xl font-bold">
      {[0, 1, 2].map((i) => (
        <motion.span
          key={i}
          animate={{ opacity: [0, 1, 0] }}
          transition={{ duration: 1.5, repeat: Infinity, delay: i * 0.2 }}
        >
          .
        </motion.span>
      ))}
    </div>
  );
}
