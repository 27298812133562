import React from 'react';
import { ToolbarComponent } from './toolbar-component';
import { Conversation } from '../api/models/Conversation';
import { useNavigate } from 'react-router-dom';
import { Editor } from '@monaco-editor/react';
import { useAppSelector } from '../store/hooks';
import { useDispatch } from 'react-redux';
import { setNotification } from '../store/notifications-reducer';
import { Artifact } from '../api/models/Artifact';
import { deleteArtifact, updateArtifact } from '../api/thunks/artifacts';
import { ArrowDownTrayIcon, TrashIcon } from '@heroicons/react/24/solid';

interface ArtifactsPageProps {
    handleUpdateConversation: (id: string, name: string) => void;
    artifacts: Artifact[];
    convo: Conversation | undefined;
    convos: Conversation[];
    handleCreateConversation: (ticker: string) => void;
    supportedTickers: string[];
    highlighter: any; // Adjust this type based on the highlighter you're using
    handleDeleteArtifact: (id: string) => void;
    handleDeleteConversation: (id: string) => Promise<void>;
    handleDeleteShareLink: (conversationId: string) => void;
}

export const ArtifactsPage: React.FC<ArtifactsPageProps> = ({ handleUpdateConversation, artifacts, convo, convos, handleCreateConversation, supportedTickers, highlighter, handleDeleteArtifact, handleDeleteConversation, handleDeleteShareLink }) => {
    document.title = 'Artifacts | PocketQuant';
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const credentials = useAppSelector((state) => state.user.credentials);
    const localArtifacts = [...artifacts];

    if (!highlighter || !credentials) return <></>;

    const handleConversationClick = (id: string) => {
        const queryParams = new URLSearchParams(window.location.search);
        queryParams.set('conversationId', id);
        navigate('/dashboard' + '?' + queryParams.toString());
        navigate(0);
    };

    const handleSaveArtifact = async (artifact: Artifact) => {
        const local = localArtifacts.find(a => a.id === artifact.id)?.data || '';
        const accessToken = credentials.accessToken;
        updateArtifact({ ...artifact, data: local }, accessToken).then(() => {
            dispatch(setNotification({ type: 'success', message: 'Artifact saved successfully' }));
        }).catch((error) => {
            dispatch(setNotification({ type: 'error', message: error.message }));
        });
    }

    return (
        <div className='grid grid-rows-[1fr_auto] h-screen overflow-hidden'>
            {/* Artifacts Grid */}
            {artifacts.length === 0 ? (
                <div className="flex justify-center items-center h-full text-lg text-gray-500">
                    No artifacts available. Start a conversation to generate artifacts.
                </div>
            ) : (
                <div className="max-w-screen-xl mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                    {localArtifacts.map((artifact) => (
                        <div
                            key={artifact.id}
                            className="m-4 rounded-lg shadow-md p-4 hover:bg-gray-50 transition-all aspect-square"
                        >
                            <div className="h-full flex flex-col justify-between p-4">
                                <h2 className="text-xl mb-2 font-semibold text-gray-700 flex justify-between items-center">
                                    <span>{artifact.name.replaceAll("\"", "")}</span>
                                    <TrashIcon onClick={() => {
                                        handleDeleteArtifact(artifact.id);
                                    }} className='w-6 h-6 cursor-pointer' />
                                </h2>
                                {artifact.artifactType === 'code' ?
                                    <>
                                        <Editor
                                            height={'100%'}
                                            defaultLanguage='python'
                                            defaultValue={artifact.data}
                                            onChange={(value) => {
                                                const a = localArtifacts.find((a) => a.id === artifact.id)
                                                if (!a) return;
                                                a.data = value || '';
                                            }}
                                            theme='vs-dark'
                                        />
                                        <div className="flex items-center justify-between">
                                            <button
                                                onClick={() => navigator.clipboard.writeText(artifact.data)}
                                                className="mt-2 px-4 py-2 bg-orange-500 text-white rounded-md text-sm w-full whitespace-nowrap"
                                            >
                                                Copy Code
                                            </button>
                                            <button onClick={() => handleSaveArtifact(artifact)} className="mt-2 ml-2 px-4 py-2 bg-orange-500 text-white rounded-md text-sm w-full" >
                                                Save Code
                                            </button>
                                        </div></>
                                    : <div className="h-full flex flex-col justify-between relative">
                                        <img
                                            src={`data:image/png;base64, ${artifact.data}`}
                                            alt={artifact.name}
                                            className="object-cover rounded-md p-2"
                                        />
                                        <button
                                            className="absolute bottom-4 right-4 bg-black bg-opacity-50 text-white flex items-center justify-center p-2 rounded-full hover:bg-opacity-75 focus:outline-none focus:ring-2 focus:ring-white transition-opacity"
                                            onClick={() => {
                                                const link = document.createElement("a");
                                                link.href = `data:image/png;base64, ${artifact.data}`;
                                                link.download = "image.png"; // Default filename
                                                link.click();
                                            }}
                                        >
                                            <ArrowDownTrayIcon className="w-5 h-5" />
                                        </button>
                                    </div>
                                }
                            </div>
                        </div>
                    ))}
                </div>
            )}

            <ToolbarComponent
                handleUpdateConversation={handleUpdateConversation}
                convo={convo}
                convos={convos}
                handleConversationClick={handleConversationClick}
                handleCreateConversation={handleCreateConversation}
                supportedTickers={supportedTickers}
                handleDeleteConversation={handleDeleteConversation}
                handleDeleteShareLink={handleDeleteShareLink}
            />
        </div>
    );
};
