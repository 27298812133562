import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../store/hooks"
import { EarningsReportComponent } from "./earnings-report-component"
import { LlmConvoComponent } from "./llm-convo-component"
import { TranscriptComponent } from "./transcript-component"
import { useLocation, useNavigate } from "react-router-dom"
import { createConversationShareLink, createMessage, getConversation } from "../api/thunks/conversations"
import { Conversation } from "../api/models/Conversation"
import { Message } from "../api/models/Message"
import { setNotification } from "../store/notifications-reducer"
import { ImgModalState } from "./image-modal"
import { ToolbarComponent } from "./toolbar-component"
import { getQuestionSuggestions } from "../api/thunks/suggestions"
import { useEarningsReport } from "../hooks/useEarningsReport"
import { useTranscript } from "../hooks/useTranscript"
import { Effort, Model } from "../api/models/CreateMessageInput"

export const DashboardComponent = ({
    handleUpdateConversation,
    convos,
    setAllConvos,
    convo,
    setConvo,
    setImgModalState,
    openCodeModal,
    supportedTickers,
    handleCreateConversation,
    handleDeleteConversation,
    handleCreateArtifact,
    setLastMessageSentTime,
    isShareMode,
    handleDeleteShareLink
}: {
    // Props remain the same
    handleUpdateConversation: (id: string, name: string) => void;
    isShareMode: boolean;
    supportedTickers: string[];
    convos: Conversation[],
    setAllConvos: (convos: Conversation[]) => void;
    convo: Conversation | undefined;
    setConvo: (convo: Conversation) => void;
    setImgModalState: (state: ImgModalState) => void;
    openCodeModal: (code: string) => void;
    handleCreateConversation: (ticker: string) => Promise<void>;
    handleDeleteConversation: (id: string) => Promise<void>;
    handleCreateArtifact: (type: 'code' | 'image', data: string) => Promise<void>;
    setLastMessageSentTime: (time: Date) => void;
    handleDeleteShareLink: (conversationId: string) => void;
}) => {
    document.title = "Dashboard | PocketQuant";
    const dispatch = useAppDispatch();
    const credentials = useAppSelector((state) => state.user.credentials);
    const navigate = useNavigate();
    
    const [message, setMessage] = useState('');
    const [questionSuggestions, setQuestionSuggestions] = useState<string[]>([]);
    
    // Use custom hooks with supportedTickers instead of convo.ticker
    const earningsReport = useEarningsReport(
      supportedTickers,
      credentials?.accessToken, 
      isShareMode
    );
    
    const transcript = useTranscript(
      supportedTickers,
      credentials?.accessToken, 
      isShareMode
    );

    const handleConversationClick = (id: string) => {
        const queryParams = new URLSearchParams(window.location.search);
        queryParams.set('conversationId', id);
        navigate('/dashboard' + '?' + queryParams.toString());
        navigate(0);
    };

    const sendMessage = async (model: Model, effort?: Effort): Promise<boolean> => {
        if (!credentials) {
            dispatch(setNotification({
                message: 'Please login to send a message',
                type: 'error'
            }));
            return false;
        }
        if (convo === undefined) {
            dispatch(setNotification({
                message: 'Please select or create a conversation',
                type: 'error'
            }));
            return false;
        }
        if (convo && message.length > 0) {
            const msgId = await createMessage(convo.id, {
                message: message,
                model: model,
                effort: effort,
                frontendContext: {
                    earningsViewSettings: {
                        ticker: earningsReport.currentTicker,
                        fiscalDateEnding: earningsReport.fiscalDateEnding || ''
                    },
                    transcriptViewSettings: {
                        ticker: transcript.currentTicker,
                        fiscalDateEnding: transcript.fiscalDateEnding || ''
                    }
                },
            }, credentials.accessToken, dispatch);

            if (!msgId) {
                return false;
            }

            const convoDeepCopy = JSON.parse(JSON.stringify(convo)) as Conversation;
            convoDeepCopy.messages?.push({
                id: msgId,
                role: Message.RoleEnum.User,
                content: [{ text: message }]
            });
            setConvo(convoDeepCopy);
            setLastMessageSentTime(new Date())
        }

        return true;
    };

    async function getCitationTextDetails(conversationId: string, messageId: string, fileId: string) {
        const msg = convo?.messages?.find(x => x.id === messageId);
        if (!msg) {
            return 'error getting citation text';
        }

        const allFileSearches = msg.runDetails.flatMap((detail: any) =>
            detail.type === "tool_calls"
                ? detail.tool_calls.filter((call: any) => call.type === "file_search")
                : []
        );

        let searchesForThisFile = [];

        for (let i = 0; i < allFileSearches.length; i++) {
            for (let j = 0; j < allFileSearches[i].file_search.results.length; j++) {
                if (allFileSearches[i].file_search.results[j].file_id === fileId) {
                    searchesForThisFile.push(allFileSearches[i].file_search.results[j]);
                }
            }
        }

        let concatenatedText = "";
        for (let i = 0; i < searchesForThisFile.length; i++) {
            concatenatedText += searchesForThisFile[i].content[0].text;
        }

        return concatenatedText;
    }

    useEffect(() => {
        if (!convo || !credentials) return;
        getQuestionSuggestions(convo.ticker, credentials.accessToken).then((data) => {
            setQuestionSuggestions(data);
        }).catch((e) => {
            console.error("Failed to get question suggestions", e);
        });
    }, [convo])

    const handleCreateShareLink = async (conversationId: string) => {
        if (!credentials || !convo) {
            return;
        }
        createConversationShareLink(conversationId, credentials?.accessToken).then((res) => {

            setConvo({ ...convo, shareLink: res })
            setAllConvos(convos.map((c) => {
                if (c.id === conversationId) {
                    return { ...c, shareLink: res }
                }
                return c;
            }));

            navigator.clipboard.writeText(res).then(() => {
                dispatch(setNotification({
                    message: 'Link created and copied to clipboard',
                    type: 'success'
                }));
            }).catch((e) => {
                dispatch(setNotification({
                    message: 'Share Link: ' + res,
                    type: 'success'
                }));
            });
        }).catch((e) => {
            dispatch(setNotification({
                message: 'Failed to create share link',
                type: 'error'
            }))
        })
    }

    return (
        <div className="grid grid-rows-[1fr_auto] h-screen overflow-hidden">
            {/* Top Row */}
            <div className="grid sm:grid-cols-1 md:grid-cols-3 gap-4 p-4 overflow-hidden">
                {/* Earnings Statement */}
                <div className="bg-white/[0.2] hidden md:block rounded-lg shadow p-4 overflow-y-auto">
                    <EarningsReportComponent
                        supportedTickers={supportedTickers}
                        setCurrentTicker={earningsReport.setCurrentTicker}
                        setCurrentFiscalDateEnding={earningsReport.setCurrentFiscalDateEnding}
                        earnings={earningsReport.report}
                        currentFiscalDateEnding={earningsReport.fiscalDateEnding || ''}
                        earningsDates={earningsReport.dates} />
                </div>
                {/* LLM Chat */}
                <div className="bg-white/[0.2] rounded-lg shadow p-4 overflow-y-auto">
                    <LlmConvoComponent 
                        createShareLink={handleCreateShareLink} 
                        questionSuggestions={questionSuggestions} 
                        createArtifact={handleCreateArtifact} 
                        openCodeModal={openCodeModal} 
                        setImgModalState={setImgModalState} 
                        getCitationTextDetails={getCitationTextDetails} 
                        convo={convo} 
                        message={message} 
                        sendMessage={sendMessage} 
                        setMessage={setMessage} />
                </div>
                {/* Transcript */}
                <div className="bg-white/[0.2] hidden md:block rounded-lg shadow p-4 overflow-y-auto">
                    <TranscriptComponent
                        setCurrentTicker={transcript.setCurrentTicker}
                        supportedTickers={supportedTickers}
                        ticker={transcript.currentTicker}
                        transcript={transcript.transcript}
                        currentFiscalDateEnding={transcript.fiscalDateEnding || ''}
                        setCurrentFiscalDateEnding={transcript.setCurrentFiscalDateEnding} 
                        earningsDates={transcript.dates} />
                </div>
            </div>

            {/* Bottom Row (Toolbar) remains the same */}
            <ToolbarComponent
                handleUpdateConversation={handleUpdateConversation}
                isShareMode={isShareMode}
                convo={convo}
                convos={convos}
                handleConversationClick={handleConversationClick}
                handleCreateConversation={handleCreateConversation}
                supportedTickers={supportedTickers}
                handleDeleteConversation={handleDeleteConversation}
                handleDeleteShareLink={handleDeleteShareLink}
            />
        </div>
    )
}