import { useState } from 'react'
import { Dialog, DialogPanel } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { navigationRoutes } from '../constants/constants'
import { TryIt } from './try-it-component';
import { ComparisonComponent } from './comparison-component';
import { FunkyHueCircleThing } from './ai-assistant-circle-thingy';
import { PricingComponent } from './pricing-component';
import { TypeAnimation } from './typing-animation';
import { Background, BackgroundVariant, Controls, Node, ReactFlow, MarkerType, Edge } from "@xyflow/react";
import '@xyflow/react/dist/style.css';
import { Mail, Cpu, DatabaseIcon, Diamond } from 'lucide-react';

export default function SplashPage() {
    document.title = 'PocketQuant';
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

    return (
        <div className="bg-white">
            <header className="absolute inset-x-0 top-0 z-50">
                <nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
                    <div className="flex lg:flex-1">
                        <a href="/" className="-m-1.5 p-1.5">
                            <span className="sr-only">PocketQuant</span>
                            <img
                                className="h-8 w-auto"
                                src="pq-icon-512.png"
                                alt=""
                            />
                        </a>
                    </div>
                    <div className="flex lg:hidden">
                        <button
                            type="button"
                            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                            onClick={() => setMobileMenuOpen(true)}
                        >
                            <span className="sr-only">Open main menu</span>
                            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>
                    <div className="hidden lg:flex lg:gap-x-12">
                        {navigationRoutes.map((item) => (
                            <a key={item.name} href={item.href} className="text-sm font-semibold leading-6 text-gray-900">
                                {item.name}
                            </a>
                        ))}
                    </div>
                    <div className="hidden lg:flex lg:flex-1 lg:justify-end flex items-center">
                        <button onClick={() => {
                            window.location.href = '/signup';
                        }} className="mt-4 py-2 px-4 bg-orange-600 ml-2 text-white font-bold rounded-lg transition duration-300">
                            Sign Up Free
                        </button>

                        <a href="/login" className="mt-4 ml-4 text-sm font-semibold leading-6 text-gray-900">
                            Log in <span aria-hidden="true">&rarr;</span>
                        </a>
                    </div>
                </nav>
                <Dialog className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
                    <div className="fixed inset-0 z-50" />
                    <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                        <div className="flex items-center justify-between">
                            <a href="/" className="-m-1.5 p-1.5">
                                <span className="sr-only">PocketQuant</span>
                                <img
                                    className="h-8 w-auto"
                                    src="pq-icon-512.png"
                                    alt=""
                                />
                            </a>
                            <button
                                type="button"
                                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                                onClick={() => setMobileMenuOpen(false)}
                            >
                                <span className="sr-only">Close menu</span>
                                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                        </div>
                        <div className="mt-6 flow-root">
                            <div className="-my-6 divide-y divide-gray-500/10">
                                <div className="space-y-2 py-6">
                                    {navigationRoutes.map((item) => (
                                        <a
                                            key={item.name}
                                            href={item.href}
                                            className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                        >
                                            {item.name}
                                        </a>
                                    ))}
                                </div>
                                <div className="py-6">
                                    <a
                                        href="/login"
                                        className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                    >
                                        Log in
                                    </a>
                                </div>
                                <div className="py-6">
                                    <a
                                        href="/signup"
                                        className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                    >
                                        Signup
                                    </a>
                                </div>
                            </div>
                        </div>
                    </DialogPanel>
                </Dialog>
            </header>

            <div className="relative isolate px-6 pt-14 lg:px-8">
                <div
                    className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
                    aria-hidden="true"
                >
                    <div
                        className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff7f50] to-[#ffa500] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                        style={{
                            clipPath:
                                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                        }}
                    />
                </div>
                <div className="mx-auto max-w-7xl py-32">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
                        {/* Left column with existing content */}
                        <div className="text-center md:text-left">
                            {/* <div className="hidden sm:mb-8 sm:flex sm:justify-center">
                                <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
                                    Announcing our latest release {' '}
                                    <a href="/changelog" className="font-semibold text-orange-600">
                                        <span className="absolute inset-0" aria-hidden="true" />
                                        Read more <span aria-hidden="true">&rarr;</span>
                                    </a>
                                </div>
                            </div> */}
                            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                                Automate investment research workflows.
                            </h1>
                            <p className="mt-6 text-2xl leading-8 text-gray-600">
                                Workflows for <b><TypeAnimation phrases={[
                                    "FED announcements",
                                    "Executive Orders",
                                    "SEC submissions",
                                    "Earnings Calls"
                                ]} /></b>
                            </p>
                            <div className="mt-10 flex items-center md:justify-start justify-center gap-x-6">
                                <a
                                    href="/try-it"
                                    className="rounded-md bg-orange-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
                                >
                                    Try It (it's free!)
                                </a>
                                <a href="/changelog" className="text-sm font-semibold leading-6 text-gray-900">
                                    Learn more <span aria-hidden="true">→</span>
                                </a>
                            </div>
                        </div>

                        {/* Right column with ReactFlow */}
                        <div className="h-[500px] border border-gray-200 rounded-lg shadow-md overflow-hidden">
                            <ReactFlowDiagram />
                        </div>
                    </div>
                </div>

                <TryIt showNav={false} showTabs={false} />

                <ComparisonComponent showNav={false} />

                <FunkyHueCircleThing />

                <PricingComponent />
            </div>

            {/* Footer Section */}
            <footer className="bg-gray-100 py-6 mt-12">
                <div className="container mx-auto px-6 lg:px-8">
                    <div className="flex justify-center space-x-6">
                        <a href="/terms-of-service" className="text-sm text-gray-600 hover:text-gray-900">
                            Terms of Service
                        </a>
                        <a href="/privacy-policy" className="text-sm text-gray-600 hover:text-gray-900">
                            Privacy Policy
                        </a>
                    </div>
                </div>
            </footer>
        </div>
    )
}

function ReactFlowDiagram() {
    const getStepIcon = (stepType: string) => {
        const wh = 'w-6 h-6 '
        switch (stepType) {
            case "data-source":
                return <DatabaseIcon className={wh + "text-white"} />;
            case "llm":
                return <Cpu className={wh + "text-white"} />;
            case "email":
                return <Mail className={wh + "text-white"} />;
            case "decision":
                return <Diamond className={wh + "text-white"} />;
            default:
                return <DatabaseIcon className={wh + "text-white"} />;
        }
    };

    const initialNodes: Node[] = [
        {
            id: '1',
            type: 'input',
            data: { 
                label: (
                    <div className="flex items-center">
                        {getStepIcon("data-source")}
                        <span className="ml-2">Earnings Call</span>
                    </div>
                ) 
            },
            position: { x: 50, y: 50 },
            style: { background: '#f97316', color: 'white', border: 'none', borderRadius: '8px' }
        },
        {
            id: '2',
            data: { 
                label: (
                    <div className="flex items-center">
                        {getStepIcon("llm")}
                        <span className="ml-2">AI Analysis</span>
                    </div>
                )
            },
            position: { x: 250, y: 150 },
            style: { background: '#3b82f6', color: 'white', border: 'none', borderRadius: '8px' }
        },
        {
            id: '3',
            type: 'output',
            data: { 
                label: (
                    <div className="flex items-center">
                        {getStepIcon("email")}
                        <span className="ml-2">Email Me</span>
                    </div>
                )
            },
            position: { x: 450, y: 50 },
            style: { background: '#10b981', color: 'white', border: 'none', borderRadius: '8px' }
        },
    ];

    const initialEdges: Edge[] = [
        { id: 'e1-2', source: '1', target: '2', animated: true, markerEnd: { type: MarkerType.ArrowClosed } },
        { id: 'e2-3', source: '2', target: '3', animated: true, markerEnd: { type: MarkerType.ArrowClosed } },
    ];

    return (
        <ReactFlow
            nodes={initialNodes}
            edges={initialEdges}
            fitView
        >
            <Background variant={BackgroundVariant.Dots} gap={12} size={1} />
            <Controls />
        </ReactFlow>
    );
}
