import { PlusCircleIcon, TrashIcon, PencilIcon } from "@heroicons/react/24/solid";
import { useState } from "react";
import { Conversation } from "../api/models/Conversation";
import { Unlink } from "lucide-react";

interface ConversationPopupProps {
  conversations: Conversation[];
  onConvoClicked: (id: string) => void;
  tickers: string[];
  onCreateConversation: (ticker: string) => void;
  onUpdateConversation: (id: string, name: string) => void; // Add this prop
  position: { top: number; left: number }; // Position relative to the button
  currentConvo: Conversation | undefined;
  handleDeleteConversation: (id: string) => void;
  handleDeleteShareLink: (conversationId: string) => void;
}

export const ConversationPopup: React.FC<ConversationPopupProps> = ({
  conversations,
  onConvoClicked,
  tickers,
  onCreateConversation,
  onUpdateConversation,
  position,
  currentConvo,
  handleDeleteConversation,
  handleDeleteShareLink
}) => {
  const [showTickerSelector, setShowTickerSelector] = useState(false);
  const [selectedTicker, setSelectedTicker] = useState<string | null>(null);
  const [editingConversationId, setEditingConversationId] = useState<string>(''); // Track which conversation is being edited
  const [editedConversations, setEditedConversations] = useState<{[key: string]: string}>({}); // Track edited conversation names

  const handleTickerSelect = (ticker: string) => {
    setSelectedTicker(ticker);
    setShowTickerSelector(false);
    onCreateConversation(ticker);
  };

  const startEditing = (convo: Conversation, e: React.MouseEvent) => {
    e.stopPropagation();
    setEditingConversationId(convo.id);
    setEditedConversations({
      ...editedConversations,
      [convo.id]: convo.name
    });
  };

  const handleNameChange = (id: string, name: string) => {
    setEditedConversations({
      ...editedConversations,
      [id]: name
    });
  };

  const saveConversationName = (id: string, e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onUpdateConversation(id, editedConversations[id]);
    setEditingConversationId('');
  };

  const cancelEditing = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setEditingConversationId('');
  };

  return (
    <div
      className="absolute bg-white rounded-lg shadow-lg w-80 p-4 z-[12] sm:max-w-[20rem] max-w-[90vw]"
      style={{
        maxHeight: '50vh',
        overflow: 'auto',
        position: 'fixed', // Change to fixed positioning
        top: window.innerWidth <= 640 ? '50%' : position.top - 10, // Center vertically on mobile
        left: window.innerWidth <= 640 ? '50%' : position.left,
        transform: window.innerWidth <= 640 
          ? 'translate(-50%, -50%)' // Center both axes on mobile
          : 'translateY(-100%)',    // Original desktop positioning
      }}
    >
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-semibold">Conversations</h2>
        <button
          onClick={() => onCreateConversation('')}
          className="p-2 rounded-full focus:outline-none"
        >
          <PlusCircleIcon className="w-6 h-6" />
        </button>
      </div>

      <ul className="mt-4 space-y-2">
        {conversations.map((convo) => (
          <li
            key={convo.id}
            className={`hover:bg-gray-100 p-2 rounded-md ${convo.id === currentConvo?.id ? 'bg-gray-100' : ''} flex justify-between items-center`}
          >
            {editingConversationId === convo.id ? (
              // Edit mode
              <div className="flex items-center w-full gap-1">
                <div className="flex-1">
                  <input
                    className="w-full rounded-md border-gray-300 p-1.5 text-gray-900 shadow-sm focus:ring-2 focus:ring-orange-600"
                    type="text"
                    value={editedConversations[convo.id]}
                    onChange={(e) => handleNameChange(convo.id, e.target.value)}
                    autoFocus
                    onClick={(e) => e.stopPropagation()}
                  />
                </div>
                <div className="flex items-center shrink-0">
                  <button
                    onClick={(e) => saveConversationName(convo.id, e)}
                    className="text-blue-600 whitespace-nowrap px-2"
                  >
                    Save
                  </button>
                  <div
                    onClick={cancelEditing}
                    className="text-gray-500 cursor-pointer px-2"
                  >
                    Cancel
                  </div>
                </div>
              </div>
            ) : (
              // View mode
              <>
                <span className="cursor-pointer" onClick={() => onConvoClicked(convo.id)}>{convo.name}</span>
                <div className="flex items-center ml-auto">
                  {convo.shareLink && (
                    <div className="relative group z-50 mr-2">
                      <Unlink onClick={() => handleDeleteShareLink(convo.id)} className="w-4 h-4 text-red-400 cursor-pointer" />
                      <div className="absolute bottom-full mb-2 hidden group-hover:block w-32 p-2 bg-gray-700 text-white text-sm rounded-lg shadow-lg">
                        Delete share link
                      </div>
                    </div>
                  )}
                  <PencilIcon 
                    className="w-4 h-4 text-gray-400 hover:text-blue-600 cursor-pointer mr-2" 
                    onClick={(e) => startEditing(convo, e)}
                  />
                  <TrashIcon 
                    className="w-4 h-4 text-gray-400 hover:text-red-600 cursor-pointer" 
                    onClick={() => handleDeleteConversation(convo.id)} 
                  />
                </div>
              </>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};