import { ArrowLeftStartOnRectangleIcon, ChartBarSquareIcon, ChatBubbleLeftRightIcon, CubeTransparentIcon, FolderIcon, QuestionMarkCircleIcon, RocketLaunchIcon, SparklesIcon, UserIcon } from "@heroicons/react/24/solid"
import { ConversationPopup } from "./conversation-list-popup"
import { useState } from "react";
import { Conversation } from "../api/models/Conversation";
import { FAQPopup } from "./faq-popup";

interface ToolbarProps {
    isShareMode?: boolean;
    convo: Conversation | undefined;
    convos: Conversation[];
    handleConversationClick: (id: string) => void;
    handleCreateConversation: (ticker: string) => void;
    supportedTickers: string[];
    handleDeleteConversation: (id: string) => Promise<void>;
    handleDeleteShareLink: (conversationId: string) => void;
    handleUpdateConversation: (id: string, name: string) => void;
}

export const ToolbarComponent = ({ isShareMode, convo, convos, handleConversationClick, handleCreateConversation, supportedTickers, handleDeleteConversation, handleDeleteShareLink, handleUpdateConversation }: ToolbarProps) => {
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [popupPosition, setPopupPosition] = useState<{ top: number; left: number }>({ top: 0, left: 0 });
    const [isFAQPopupOpen, setFAQPopupOpen] = useState(false);
    const [faqPopupPosition, setFAQPopupPosition] = useState<{ top: number; left: number }>({ top: 0, left: 0 });
    return (
        <>
            <div className={`flex items-center justify-start md:justify-center gap-4 md:gap-10 bg-gray-800 text-white py-2 px-6 md:px-4 overflow-x-auto md:overflow-hidden ${isShareMode ? 'hidden' : ''}`}>
                <button className="flex flex-col items-center disabled:bg-grey-300" onClick={() => {
                    window.location.href = '/profile';
                }}>
                    <UserIcon className="w-6 h-6" />
                    <span className="text-xs">Profile</span>
                </button>

                {/* <button className="flex flex-col items-center" onClick={() => {
                    window.location.href = '/dashboard';
                }}>
                    <ChartBarSquareIcon className="w-6 h-6" />
                    <span className="text-xs">Dashboard</span>
                </button> */}

                {/* <button className="flex flex-col items-center">
                    <CogIcon className="w-6 h-6" />
                    <span className="text-xs">Settings</span>
                </button> */}

                <button id="conversations-btn" className="flex flex-col items-center" onClick={() => {
                    setPopupOpen(!isPopupOpen)
                    const button = document.getElementById('conversations-btn');
                    if (button) {
                        const rect = button.getBoundingClientRect();
                        setPopupPosition({
                            top: rect.top,
                            left: rect.left + rect.width / 2 - 160, // Center the popup above the button
                        });
                    }
                }}>
                    <ChatBubbleLeftRightIcon className="w-6 h-6" />
                    <span className="text-xs">Conversations</span>
                </button>
                <button className="flex flex-col items-center" onClick={() => {
                    const queryParams = new URLSearchParams(window.location.search);
                    window.location.href = '/workflows?' + queryParams.toString();
                }}>
                    <RocketLaunchIcon className="w-6 h-6" />
                    <span className="text-xs">Workflows</span>
                </button>
                <button className="flex flex-col items-center" onClick={() => {
                    window.location.href = '/artifacts';
                }}>
                    <FolderIcon className="w-6 h-6" />
                    <span className="text-xs">Artifacts</span>
                </button>
                <button className="flex flex-col items-center" onClick={() => {
                    setFAQPopupOpen(!isFAQPopupOpen)
                    const button = document.getElementById('conversations-btn');
                    if (button) {
                        const rect = button.getBoundingClientRect();
                        setFAQPopupPosition({
                            top: rect.top - 190,
                            left: rect.left + rect.width, // Center the popup above the button
                        });
                    }
                }}>
                    <QuestionMarkCircleIcon className="w-6 h-6" />
                    <span className="text-xs">FAQ</span>
                </button>
                <button className="flex flex-col items-center" onClick={() => {
                    sessionStorage.clear();
                    window.location.href = '/';
                }}>
                    <ArrowLeftStartOnRectangleIcon className="w-6 h-6" />
                    <span className="text-xs">Logout</span>
                </button>
            </div>

            {
                isPopupOpen && (
                    <ConversationPopup
                    onUpdateConversation={handleUpdateConversation}
                        conversations={convos}
                        onConvoClicked={handleConversationClick}
                        onCreateConversation={handleCreateConversation}
                        tickers={supportedTickers}
                        position={popupPosition}
                        currentConvo={convo}
                        handleDeleteConversation={handleDeleteConversation}
                        handleDeleteShareLink={handleDeleteShareLink}
                    />
                )
            }

            {
                isFAQPopupOpen && (
                    <FAQPopup faqs={[
                        {
                            id: '1',
                            question: "I can't see the dropdowns for the earnings and transcript components.",
                            answer: "Disable content blockers for this site."
                        },
                        {
                            id: '2',
                            question: 'How do I create a conversation?',
                            answer: 'Click on the conversations button then click the + icon. Scroll through the list or start typing the ticker of the company you\'re interested in and click on it.'
                        },
                        {
                            id: '3',
                            question: 'Are there keyboard shortcuts?',
                            answer:
                                <div>
                                    <ul>
                                        <li>
                                            <kbd className="px-2 py-1 text-xs font-semibold text-gray-800 bg-gray-200 rounded-lg border border-gray-300 shadow-sm">CNTRL</kbd> + <kbd className="px-2 py-1 text-xs font-semibold text-gray-800 bg-gray-200 rounded-lg border border-gray-300 shadow-sm">S</kbd> will open up the search for tickers and selecting one will create a new conversation.
                                        </li>
                                        <li className="mt-2">
                                            <kbd className="px-2 py-1 text-xs font-semibold text-gray-800 bg-gray-200 rounded-lg border border-gray-300 shadow-sm">↑</kbd> and <kbd className="px-2 py-1 text-xs font-semibold text-gray-800 bg-gray-200 rounded-lg border border-gray-300 shadow-sm">↓</kbd> will navigate through the search results.
                                        </li>
                                        <li className="mt-2">
                                            <kbd className="px-2 py-1 text-xs font-semibold text-gray-800 bg-gray-200 rounded-lg border border-gray-300 shadow-sm"
                                            >TAB</kbd> will autocomplete suggestions in the message input.
                                        </li>
                                    </ul>
                                </div>
                        },
                        {
                            id: '4',
                            question: "The company I'm interested in isn't in the list, what do I do?",
                            answer: "Email tristan@pocket-quant.com and we'll add it to the list."
                        },
                        {
                            id: '5',
                            question: "How do I get in touch with the team?",
                            answer: <>Email <a className="text-indigo-500" href="mailto:tristan@pocket-quant.com>">tristan@pocket-quant.com</a> </>
                        },
                    ]} onFAQClicked={() => { }} position={faqPopupPosition} onClose={() => setFAQPopupOpen(false)} />
                )
            }
        </>
    )
}