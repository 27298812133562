import { useNavigate } from "react-router-dom"
import { funnelRoutes } from "../constants/constants";

export const PricingComponent = () => {
    if (window.location.pathname === "/pricing") {
        document.title = "Pricing | PocketQuant";
    }
    const navigate = useNavigate();
    const excludedHrefs = ['/pricing'];
    const localNavigation = [...funnelRoutes.filter((x) => !excludedHrefs.includes(x.href))];
    return (
        <div className="max-w-6xl mx-auto py-12">
            {/* Header */}
            <div className="text-center mb-6 mt-6">
                <h1 className="text-3xl md:text-4xl font-bold mb-4">When time is money, pick PocketQuant</h1>
            </div>

            {/* Pricing Cards */}
            <div className="flex flex-col md:flex-row justify-center space-y-6 md:space-y-0 md:space-x-8">

                {/* Free Plan */}
                <div className="border rounded-lg shadow-lg p-8 w-full md:w-80 flex flex-col justify-between">
                    <div>
                        <h2 className="text-xl font-semibold">Free</h2>
                        <div className="mb-4">Powerful tools, for free</div>
                        <div className="text-4xl font-bold mb-4">$0<span className="text-sm font-normal">USD/month</span></div>
                        <ul className="space-y-4 mb-6">
                            <li className="flex items-center"><span className="text-orange-500 mr-2">✓</span> 10 copilot prompts</li>
                            <li className="flex items-center"><span className="text-orange-500 mr-2">✓</span> 2nd Most recent earnings report</li>
                            <li className="flex items-center"><span className="text-orange-500 mr-2">✓</span> 2nd Most recent transcript</li>
                        </ul>
                    </div>
                    <button onClick={() => navigate('/signup')} className="w-full bg-orange-500 text-white py-2 rounded-lg mt-6">Get Started</button>
                </div>

                {/* Premium Plan */}
                <div className="border rounded-lg shadow-lg p-8 w-full md:w-80 bg-orange-50 flex flex-col justify-between">
                    <div>
                        <h2 className="text-xl font-semibold">Premium <span className="text-sm font-medium text-orange-600">(Cheaper than ChatGPT)</span></h2>
                        <div className="mb-4">Better investment research</div>
                        <div className="text-4xl font-bold mb-4">$10<span className="text-sm font-normal">USD/month</span></div>
                        <ul className="space-y-4 mb-6">
                            <li className="flex items-center"><span className="text-orange-500 mr-2">✓</span> 100 monthly prompts</li>
                            <li className="flex items-center"><span className="text-orange-500 mr-2">✓</span> Earnings reports since 2009</li>
                            <li className="flex items-center"><span className="text-orange-500 mr-2">✓</span> Transcripts since 2021</li>
                        </ul>
                    </div>
                    <button onClick={() => navigate('/signup?redirect=checkout&price_id=' + process.env.REACT_APP_PQ_PREMIUM_PRICE_ID)} className="w-full bg-orange-500 text-white py-2 rounded-lg mt-6">Buy plan</button>
                </div>

                {/* Pro Plan */}
                <div className="border rounded-lg shadow-lg p-8 w-full md:w-80 flex flex-col justify-between">
                    <div>
                        <h2 className="text-xl font-semibold">Pro</h2>
                        <div className="mb-4">Automate investment research</div>
                        <div className="text-4xl font-bold mb-4">$49<span className="text-sm font-normal">USD/month</span></div>
                        <ul className="space-y-4 mb-6">
                            <li className="flex items-center"><span className="text-orange-500 mr-2">✓</span> 250 monthly prompts</li>
                            <li className="flex items-center"><span className="text-orange-500 mr-2">✓</span> Access to o3-mini</li>
                            <li className="flex items-center"><span className="text-orange-500 mr-2">✓</span> Earnings reports since 2009</li>
                            <li className="flex items-center"><span className="text-orange-500 mr-2">✓</span> Transcripts since 2021</li>
                            <li className="flex items-center"><span className="text-orange-500 mr-2">✓</span> 10 Workflow executions per month</li>
                            <li className="flex items-center"><span className="text-orange-500 mr-2">✓</span> Feature/Data source requests</li>
                        </ul>
                    </div>
                    <button onClick={() => navigate('/signup?redirect=checkout&price_id=' + process.env.REACT_APP_PQ_PRO_PRICE_ID)} className="w-full bg-orange-500 text-white py-2 rounded-lg mt-6">Buy plan</button>
                </div>
            </div>
        </div>
    )
}
