import { useState, useEffect } from 'react';

interface TypeAnimationProps {
  phrases: string[];
  typingSpeed?: number;
  deletingSpeed?: number;
  delayBetweenPhrases?: number;
}

export const TypeAnimation = ({
  phrases,
  typingSpeed = 50,
  deletingSpeed = 50,
  delayBetweenPhrases = 1000,
}: TypeAnimationProps) => {
  const [currentText, setCurrentText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      // Current phrase we're working with
      const currentPhrase = phrases[currentIndex];
      
      if (isDeleting) {
        // Deleting mode - remove characters
        setCurrentText(currentPhrase.substring(0, currentText.length - 1));
        
        // If all characters are deleted, start typing the next phrase
        if (currentText.length === 0) {
          setIsDeleting(false);
          setCurrentIndex((prev) => (prev + 1) % phrases.length);
        }
      } else {
        // Typing mode - add characters
        setCurrentText(currentPhrase.substring(0, currentText.length + 1));
        
        // If full phrase is typed, pause and then start deleting
        if (currentText.length === currentPhrase.length) {
          setTimeout(() => {
            setIsDeleting(true);
          }, delayBetweenPhrases);
        }
      }
    }, isDeleting ? deletingSpeed : typingSpeed);
    
    return () => clearTimeout(timeout);
  }, [currentText, currentIndex, isDeleting, phrases, typingSpeed, deletingSpeed, delayBetweenPhrases]);

  return <span>{currentText}</span>;
};