import { useNavigate } from "react-router-dom";
import { Conversation } from "../api/models/Conversation";
import { ToolbarComponent } from "./toolbar-component";
import { addEdge, Background, BackgroundVariant, Controls, Handle, Node, NodeProps, Position, ReactFlow, useEdgesState, useNodesState, useReactFlow } from "@xyflow/react";
import { ArrowsPointingOutIcon, ChevronLeftIcon, ChevronRightIcon, InformationCircleIcon, PencilIcon, PlayIcon, PlusCircleIcon, TrashIcon } from "@heroicons/react/24/solid";
import '@xyflow/react/dist/style.css';
import { useCallback, useEffect, useState } from "react";
import { Step, StepStatus, StepType, Workflow, WorkflowExecutionLog } from "../api/models/Workflows";
import { Mail, Cpu, FileIcon, DatabaseIcon, Clock, ScrollTextIcon, CloudAlertIcon, ToggleLeftIcon, ToggleRightIcon, X, Diamond, AlertTriangleIcon, Check, ArrowLeftRight, BeakerIcon, FlaskConical } from 'lucide-react';
import { useDispatch } from "react-redux";
import NotificationPopup from "./notification-popup-component";
import { setNotification } from "../store/notifications-reducer";
import { createStep, createWorkflow, deleteStep, deleteWorkflow, getAllWorkflows, getWorkflow, testWorkflow, updateStep, updateWorkflow } from "../api/thunks/workflows";
import { useAppSelector } from "../store/hooks";
import { Assistant } from "../api/models/Assistant";
import { JSONSchemaInput } from "./json-schema-input";
import dayjs from 'dayjs'; // Import dayjs for date manipulation
import duration from 'dayjs/plugin/duration'; // Import duration plugin for dayjs
dayjs.extend(duration);

const nodeTypes = {
    email: EmailNode,
    llm: LLMNode,
    'data-source': DataSourceNode,
    'menuNode': MenuNode,
    "decision": DecisionStepNode,
    "terminal": TerminalNode,
    "workflowExecutionLogNode": WorkflowExecutionLogNode,
}

export const WorkflowsPage = ({
    handleUpdateConversation,
    workflows,
    setWorkflows,
    assistants,
    convo,
    convos,
    handleCreateConversation,
    handleDeleteConversation,
    handleDeleteShareLink,
    supportedTickers
}: {
    handleUpdateConversation: (id: string, name: string) => void;
    workflows: Workflow[];
    assistants: Assistant[];
    setWorkflows: (w: Workflow[]) => void;
    convo: Conversation | undefined;
    convos: Conversation[];
    handleCreateConversation: (ticker: string) => void;
    supportedTickers: string[];
    handleDeleteConversation: (id: string) => Promise<void>;
    handleDeleteShareLink: (conversationId: string) => void;
}) => {
    const user = useAppSelector((state) => state.user);
    const dispatch = useDispatch();
    const [selectedWorkflow, setSelectedWorkflow] = useState<Workflow | undefined>(workflows.length > 0 ? workflows[0] : undefined);
    const navigate = useNavigate();
    const nodeStyle = {
        color: '#c2400c',
        borderColor: '#c2400c',
    };

    const handleConversationClick = (id: string) => {
        const queryParams = new URLSearchParams(window.location.search);
        queryParams.set("conversationId", id);
        navigate("/dashboard" + "?" + queryParams.toString());
        navigate(0);
    };

    const [nodes, setNodes, onNodesChange] = useNodesState(new Array<any>());
    const [edges, setEdges, onEdgesChange] = useEdgesState(new Array<any>());
    const [showEditWorkflowName, setShowEditWorkflowName] = useState('');
    const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(() => {
        return window.innerWidth < 768; // Initially collapsed on screens smaller than md breakpoint
    });

    // Listen for window resize to update sidebar state
    useEffect(() => {

        const handleResize = () => {
            // Only automatically expand on desktop, don't auto-collapse when resizing down
            if (window.innerWidth >= 768 && isSidebarCollapsed) {
                setIsSidebarCollapsed(false);
            }
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [isSidebarCollapsed]);

    // Auto-collapse sidebar on selected workflow change on mobile
    useEffect(() => {
        if (selectedWorkflow && window.innerWidth < 768) {
            setIsSidebarCollapsed(true);
        }
    }, [selectedWorkflow]);

    const onConnect = useCallback(
        (params: any) => setEdges((eds) => {
            return addEdge({ ...params, animated: true }, eds)
        }),
        [setEdges],
    );

    useEffect(() => {
        if (nodes.filter(e => e.type !== 'menuNode').length <= 1 || !selectedWorkflow || !user.credentials?.accessToken) return;
        // if not every node is connected then don't update order of workflow
        if (nodes.length > 1 && edges.length == 0 || !everyNodeConnected()) {
            dispatch(setNotification({
                type: 'info',
                message: 'Every node needs to be connected to update the workflow'
            }))
            return
        }

        // step order implied by the order of the nodes
        const stepIdToOrder: { [key: string]: number } = {};
        const filteredEdges = edges.filter(e => e.id.indexOf('_decision_to_terminal') === -1) // get all the edges that aren't pointing to a decision node's (END) node
        for (let i = 0; i < filteredEdges.length; i += 1) {
            const edge = filteredEdges[i];
            stepIdToOrder[edge.source] = i + 1;
        }

        // the target of the last node is the last step
        stepIdToOrder[filteredEdges[filteredEdges.length - 1].target] = filteredEdges.length + 1;

        let orderHasChanged = false;
        const steps = selectedWorkflow.steps;
        for (let i = 0; i < steps.length; i++) {
            if (steps[i].order !== stepIdToOrder[steps[i].id!]) {
                orderHasChanged = true;
                break;
            }
        }

        if (!orderHasChanged) {
            return;
        }

        for (let i = 0; i < steps.length; i++) {
            steps[i].order = stepIdToOrder[steps[i].id!];
            updateStep(user.credentials?.accessToken, selectedWorkflow.id!, steps[i].id!, steps[i]).then(() => {
                dispatch(setNotification({
                    type: 'success',
                    message: 'Step order updated successfully'
                }));
            }).catch((reason) => {
                dispatch(setNotification({
                    type: 'error',
                    message: 'Failed to update step order'
                }));
            });
        }

        getWorkflow(user.credentials?.accessToken!, selectedWorkflow.id!).then((updatedWorkflow) => {
            const newWorkflows = workflows.map((w) => {
                if (w.id === updatedWorkflow.id) {
                    return updatedWorkflow;
                }
                return w;
            });
            setWorkflows(newWorkflows);
            setSelectedWorkflow(updatedWorkflow);
        }).catch((reason) => {
            dispatch(setNotification({
                type: 'error',
                message: 'Failed to get workflow after updating step order'
            }));
        });

    }, [edges, selectedWorkflow]);

    const everyNodeConnected = () => {
        const steps = selectedWorkflow?.steps;
        if (!steps) return false;
        const isConnected: { [key: string]: boolean } = {};
        for (let i = 0; i < steps.length; i++) {
            isConnected[steps[i].id!] = false;
        }

        for (let i = 0; i < edges.length; i++) {
            const edge = edges[i];
            isConnected[edge.source] = true;
            isConnected[edge.target] = true;
        }

        for (let i = 0; i < steps.length; i++) {
            if (!isConnected[steps[i].id!]) {
                return false;
            }
        }

        return true;
    }

    const handleDeleteNodes = (nodes: any[]) => {
        if (!user?.credentials?.accessToken || !selectedWorkflow?.id) {
            return;
        }
        for (let i = 0; i < nodes.length; i++) {
            deleteStep(user.credentials?.accessToken, selectedWorkflow?.id, nodes[i].id).then(async () => {
                getWorkflow(user.credentials!.accessToken, selectedWorkflow.id!).then((workflow) => {
                    setSelectedWorkflow(workflow);
                    dispatch(setNotification({
                        type: 'success',
                        message: 'Step deleted successfully'
                    }))
                }).catch(() => {
                    dispatch(setNotification({
                        type: 'error',
                        message: 'Failed to get workflow after deleting step'
                    }));
                });
            }).catch(() => {
                dispatch(setNotification({
                    type: 'error',
                    message: 'Failed to delete step'
                }))
            })
        }
    }

    useEffect(() => {
        if (!selectedWorkflow) {
            setSelectedWorkflow(workflows[0]);
        } else {
            const workflow = workflows.find(w => w.id === selectedWorkflow.id);
            if (!workflow) {
                setSelectedWorkflow(workflows[0]);
            } else {
                setSelectedWorkflow(workflow);
            }
        }
    }, [workflows])

    useEffect(() => {
        if (!selectedWorkflow) {
            return
        };
        const edges = [];
        const nodes: any[] = [];
        const steps = selectedWorkflow.steps;

        // Process regular workflow steps
        for (let i = 0; i < steps.length; i++) {
            nodes.push({
                id: steps[i].id,
                type: steps[i].stepType,
                data: {
                    label: steps[i].stepType,
                    supportedTickers: supportedTickers,
                    assistants: assistants,
                    step: steps[i],
                    workflowID: selectedWorkflow.id,
                },
                position: {
                    x: i > 0 ? nodes[i - 1].position.x + 400 : 0,
                    y: 0
                },
                style: nodeStyle,
                sourcePosition: 'right',
                targetPosition: 'left',
            });
        }

        // Add terminal nodes for decision steps
        steps.forEach((step, i) => {
            if (step.stepType === 'decision') {
                const terminalId = `decision-${step.id}`;
                // Add terminal node below the decision node
                nodes.push({
                    id: terminalId,
                    type: 'terminal',
                    data: {},
                    position: {
                        // Position terminal node below its decision node
                        x: nodes.find(n => n.id === step.id)?.position.x + 110,
                        y: nodes.find(n => n.id === step.id)?.position.y + step.decisionStep?.isSmartDecisionStep ? 250 : 350
                    },
                });

                // Add edge from decision bottom handle to terminal node
                edges.push({
                    id: `e${step.id}_decision_to_terminal`,
                    source: step.id,
                    target: terminalId,
                    sourceHandle: "bottom", // Explicitly use the bottom handle
                    targetHandle: "top",    // Connect to the top handle of terminal node
                    animated: true,
                    style: {
                        stroke: "#c2400c",
                    },
                    label: "No"
                });
            }
        });

        // Add menu node
        nodes.push({
            id: 'menuNode',
            type: 'menuNode',
            position: {
                x: 0,
                y: -250,
            },
            data: {
                setSelectedWorkflow,
                workflow: selectedWorkflow,
                supportedTickers
            },
        });

        nodes.push({
            id: 'workflowExecutionLogNode',
            type: 'workflowExecutionLogNode',
            position: {
                x: 0,
                y: 250,
            },
            data: {
                workflow: selectedWorkflow,
            },
        })

        // Add regular workflow edges
        for (let i = 0; i < steps.length - 1; i++) {
            edges.push({
                id: `e${steps[i].id}__${steps[i + 1].id}`,
                source: steps[i].id,
                target: steps[i + 1].id,
                animated: true,
                style: {
                    stroke: "#c2400c"
                },
                sourceHandle: steps[i].stepType === 'decision' ? 'right' : undefined, // undefined because the other step types don't have IDs on their handles
                label: steps[i].stepType === 'decision' ? 'Yes' : ''
            });
        }

        setNodes(nodes);
        setEdges(edges);
    }, [selectedWorkflow, supportedTickers])

    const hanldeUpdateWorkflowEnabled = (workflow: Workflow) => {
        if (!user.credentials?.accessToken) {
            return;
        }
        updateWorkflow(user.credentials.accessToken, workflow.id, workflow.name || 'name missing', !workflow.enabled).then(() => {
            dispatch(setNotification({
                type: 'success',
                message: 'Workflow updated successfully'
            }));
            getWorkflow(user!.credentials!.accessToken, workflow.id).then((workflow) => {
                setSelectedWorkflow(workflow);
                setWorkflows(workflows.map((w) => {
                    if (w.id === workflow.id) {
                        return workflow;
                    }
                    return w;
                }));
            }).catch((reason) => {
                dispatch(setNotification({
                    type: 'error',
                    message: 'Failed to get workflow after updating'
                }));
            });
        }).catch((reason) => {
            dispatch(setNotification({
                type: 'error',
                message: `${reason}`
            }));
        });
    }

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const workflowIdFromUrl = queryParams.get('workflowId');

        if (workflowIdFromUrl && workflows.length > 0) {
            const workflowFromUrl = workflows.find(w => w.id === workflowIdFromUrl);
            if (workflowFromUrl) {
                setSelectedWorkflow(workflowFromUrl);
            }
        }
    }, [workflows]);

    return (
        <>
            <NotificationPopup />
            <div className="grid grid-rows-[calc(100vh-60px)_60px] h-screen overflow-hidden">
                {/* Collapsed state toggle button - outside the grid for better positioning */}
                <button
                    className="fixed top-4 left-4 z-[999] bg-white p-2.5 rounded-md shadow-lg border-2 border-orange-500 md:hidden flex items-center gap-2"
                    style={{ display: isSidebarCollapsed ? 'flex' : 'none' }}
                    onClick={() => setIsSidebarCollapsed(false)}
                    aria-label="Open workflows list"
                >
                    <ChevronRightIcon className="h-5 w-5 text-orange-600" />
                    <span className="text-sm font-medium text-gray-700">Workflows</span>
                </button>

                <div className="grid grid-cols-1 h-full relative">
                    {/* Workflow List */}
                    <div
                        className={`absolute z-10 bg-white h-full transition-all duration-300 border-r border-gray-300
                            ${isSidebarCollapsed
                                ? 'w-0 -translate-x-full opacity-0'
                                : 'w-full md:w-[280px] lg:w-[300px] translate-x-0 opacity-100'}`}
                    >
                        <div className="p-2 sm:p-4 overflow-y-auto h-full">
                            <div className="flex items-center justify-between mb-4">
                                <h2 className="text-lg font-semibold">Workflows</h2>
                                <div className="flex items-center gap-2">
                                    <PlusCircleIcon
                                        onClick={() => {
                                            createWorkflow(user.credentials!.accessToken!, 'New Workflow', [
                                                {
                                                    order: 1,
                                                    stepType: 'data-source',
                                                    dataSourceStep: {
                                                        subType: 'data-source-earnings',
                                                        ticker: supportedTickers[0]
                                                    }
                                                }
                                            ]).then((id: string) => {
                                                getAllWorkflows(user.credentials!.accessToken!).then((workflows) => {
                                                    setWorkflows(workflows);
                                                    setSelectedWorkflow(workflows.find(w => w.id === id));
                                                    const queryParams = new URLSearchParams(window.location.search);
                                                    queryParams.set('workflowId', id);
                                                    navigate(`/workflows?${queryParams.toString()}`, { replace: true });
                                                }).catch((reason) => {
                                                    dispatch(setNotification({
                                                        type: 'error',
                                                        message: "" + reason
                                                    }));
                                                });
                                            }).catch((reason) => {
                                                dispatch(setNotification({
                                                    type: 'error',
                                                    message: 'Failed to create workflow'
                                                }));
                                            });
                                        }}
                                        className="text-gray-500 hover:text-black w-6 h-6 cursor-pointer"
                                    />
                                    <ChevronLeftIcon
                                        className="h-6 w-6 text-gray-500 hover:text-gray-700 cursor-pointer md:hidden"
                                        onClick={() => setIsSidebarCollapsed(true)}
                                        aria-label="Close workflows list"
                                    />
                                </div>
                            </div>

                            <ul className="space-y-2">
                                {workflows.map((w) => (
                                    <li
                                        key={w.id}
                                        className={`p-2 rounded-lg shadow hover:bg-gray-200 cursor-pointer flex items-center ${selectedWorkflow?.id === w.id ? 'bg-gray-200' : ''}`}
                                        onClick={() => {
                                            setSelectedWorkflow(w);
                                            const queryParams = new URLSearchParams(window.location.search);
                                            queryParams.set('workflowId', w.id);
                                            // Remove conversationId if present
                                            queryParams.delete('conversationId');
                                            navigate(`/workflows?${queryParams.toString()}`, { replace: true });
                                        }}
                                    >
                                        {showEditWorkflowName !== w.id &&
                                            <div className="flex items-center justify-between w-full">
                                                <div className="flex items-center gap-2 min-w-0 flex-1">
                                                    {w.enabled ?
                                                        <ToggleRightIcon className="flex-shrink-0 w-5 h-5 sm:w-6 sm:h-6" onClick={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            hanldeUpdateWorkflowEnabled(w);
                                                        }} /> :
                                                        <ToggleLeftIcon className="flex-shrink-0 w-5 h-5 sm:w-6 sm:h-6" onClick={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            hanldeUpdateWorkflowEnabled(w);
                                                        }} />
                                                    }
                                                    <span className="flex-1 truncate font-medium" title={w.name}>
                                                        {w.name}
                                                    </span>
                                                </div>

                                                <div className="flex items-center gap-1 sm:gap-2">
                                                    <PlayIcon
                                                        className="w-5 h-5 text-green-700 cursor-pointer"
                                                        title="Test workflow"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            testWorkflow(user.credentials!.accessToken, w.id).then(() => {
                                                                dispatch(setNotification({
                                                                    type: 'info',
                                                                    message: 'Testing workflow...'
                                                                }));
                                                            }).catch((reason) => {
                                                                dispatch(setNotification({
                                                                    type: 'error',
                                                                    message: `error testing workflow: ${reason}`
                                                                }));
                                                            })
                                                        }}
                                                    />
                                                    <PencilIcon
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            setShowEditWorkflowName(w.id);
                                                        }}
                                                        className="w-5 h-5 text-gray-500 hover:text-blue-600 cursor-pointer"
                                                        title="Edit name"
                                                    />
                                                    <TrashIcon
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            deleteWorkflow(user.credentials!.accessToken, w.id).then(() => {
                                                                dispatch(setNotification({
                                                                    type: 'success',
                                                                    message: 'Workflow deleted successfully'
                                                                }));
                                                                getAllWorkflows(user.credentials!.accessToken!).then((workflows) => {
                                                                    setWorkflows(workflows);
                                                                    if (workflows.length === 0) {
                                                                        return;
                                                                    }
                                                                    setSelectedWorkflow(workflows[0]);
                                                                }).catch((reason) => {
                                                                    dispatch(setNotification({
                                                                        type: 'error',
                                                                        message: reason
                                                                    }));
                                                                });
                                                            }).catch((reason) => {
                                                                dispatch(setNotification({
                                                                    type: 'error',
                                                                    message: reason
                                                                }));
                                                            });
                                                        }}
                                                        className="w-5 h-5 text-gray-500 hover:text-red-600 cursor-pointer"
                                                        title="Delete workflow"
                                                    />
                                                </div>
                                            </div>
                                        }

                                        {showEditWorkflowName === w.id &&
                                            <div className="flex items-center w-full gap-1">
                                                <div className="flex-1">
                                                    <input
                                                        className="w-full rounded-md border-gray-300 p-1.5 text-gray-900 shadow-sm focus:ring-2 focus:ring-orange-600"
                                                        type="text"
                                                        value={w.name}
                                                        onChange={(e) => {
                                                            setWorkflows(workflows.map((workflow) => {
                                                                if (workflow.id === w.id) {
                                                                    return { ...workflow, name: e.target.value };
                                                                }
                                                                return workflow;
                                                            }));
                                                        }}
                                                        autoFocus
                                                    />
                                                </div>
                                                <div className="flex items-center shrink-0">
                                                    <button
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            setShowEditWorkflowName('');
                                                            updateWorkflow(user.credentials!.accessToken!, w.id, w.name!, w.enabled).then(() => {
                                                                dispatch(setNotification({
                                                                    type: 'success',
                                                                    message: 'Workflow name updated successfully'
                                                                }));
                                                                getWorkflow(user.credentials!.accessToken!, w.id).then((workflow) => {
                                                                    setSelectedWorkflow(workflow);
                                                                    setWorkflows(workflows.map((wf) => {
                                                                        if (wf.id === w.id) {
                                                                            return workflow;
                                                                        }
                                                                        return wf;
                                                                    }));
                                                                }).catch((reason) => {
                                                                    console.error('Failed to get workflow after updating name', reason);
                                                                });
                                                            }).catch((reason) => {
                                                                dispatch(setNotification({
                                                                    type: 'error',
                                                                    message: `Failed to update workflow: ${reason}`
                                                                }));
                                                            });
                                                        }}
                                                        className="text-blue-600 whitespace-nowrap px-2"
                                                    >
                                                        Save
                                                    </button>
                                                    <div
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            setShowEditWorkflowName('');
                                                            getAllWorkflows(user.credentials!.accessToken!).then((workflows) => {
                                                                setWorkflows(workflows);
                                                                if (workflows.length === 0) {
                                                                    return;
                                                                }
                                                                setSelectedWorkflow(workflows[0]);
                                                            }
                                                            ).catch((reason) => {
                                                                dispatch(setNotification({
                                                                    type: 'error',
                                                                    message: reason
                                                                }));
                                                            });
                                                        }}
                                                        className="flex items-center justify-center h-8 w-8 hover:bg-gray-300 rounded cursor-pointer"
                                                        title="Cancel"
                                                    >
                                                        <X className="w-5 h-5 text-gray-600 hover:text-red-600" />
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </li>
                                ))}
                            </ul>
                        </div>

                        {/* Collapsed state toggle button - more prominent and visible */}
                        <button
                            className={`fixed top-4 left-4 z-[50] bg-white p-2 rounded-md shadow-md border border-gray-300 md:hidden
                                ${isSidebarCollapsed ? 'opacity-100 visible' : 'opacity-0 invisible'} 
                                transition-opacity duration-300 flex items-center gap-1`}
                            onClick={() => setIsSidebarCollapsed(false)}
                            aria-label="Open workflows list"
                        >
                            <ChevronRightIcon className="h-5 w-5 text-gray-700" />
                            <span className="text-sm font-medium text-gray-700">Open Workflows</span>
                        </button>
                    </div>

                    {/* Main content area - ReactFlow */}
                    <div className={`h-full w-full transition-all duration-300 ${isSidebarCollapsed
                        ? 'ml-0'
                        : 'ml-0 md:ml-[280px] lg:ml-[300px]'
                        }`}>
                        <ReactFlow
                            nodes={nodes}
                            edges={edges}
                            onNodesChange={onNodesChange}
                            onEdgesChange={onEdgesChange}
                            onConnect={onConnect}
                            fitView
                            nodeTypes={nodeTypes}
                            onNodesDelete={handleDeleteNodes}
                        >
                            <Controls />
                            <Background variant={BackgroundVariant.Dots} gap={12} size={1} />
                        </ReactFlow>
                    </div>
                </div>

                <ToolbarComponent
                    handleUpdateConversation={handleUpdateConversation}
                    convo={convo}
                    convos={convos}
                    handleConversationClick={handleConversationClick}
                    handleCreateConversation={handleCreateConversation}
                    handleDeleteConversation={handleDeleteConversation}
                    supportedTickers={supportedTickers}
                    handleDeleteShareLink={handleDeleteShareLink}
                />
            </div >
        </>
    );
};

function EmailNode({ id, data }: NodeProps<Node<{
    step: Step,
    workflowID: string
}>>) {
    const { updateNodeData } = useReactFlow();
    const user = useAppSelector((state) => state.user);
    const dispatch = useDispatch();
    const [showSave, setShowSave] = useState(false);

    return (
        <div className="space-y-3 w-72">
            <div className="text-black flex items-center justify-center">Send Email
                {showSave && <FileIcon
                    onClick={() => {
                        updateStep(user.credentials!.accessToken, data.workflowID, data.step.id!, data.step)
                            .then(() => {
                                dispatch(setNotification({
                                    type: 'success',
                                    message: 'Step saved successfully'
                                }));
                                setShowSave(false);
                            })
                            .catch((reason) => {
                                console.error('Failed to save step', reason);
                                dispatch(setNotification({
                                    type: 'error',
                                    message: 'Failed to save step' + reason
                                }));
                            });
                    }}
                    className="w-4 h-4 cursor-pointer ml-2"
                />}
                {data.step.lastOutput && (
                    <div className="relative group z-50 flex items-center">
                        <ScrollTextIcon onClick={() => {
                            navigator.clipboard.writeText(data.step.lastOutput || '');
                            dispatch(setNotification({
                                type: 'success',
                                message: 'Copied output to clipboard'
                            }));
                        }} className="absolute transform w-4 h-4 ml-2 cursor-pointer" />
                        <div className="absolute bottom-full mb-2 hidden group-hover:block w-64 p-2 bg-gray-700 text-white text-sm rounded-lg shadow-lg">
                            {data.step.lastOutput || 'Step has not run yet'}
                        </div>
                    </div>
                )}
            </div>

            <div className="flex items-center space-x-2">
                <label className="w-16 text-right text-black">Subject</label>
                <input
                    className="flex-1 rounded-md border-gray-300 p-1.5 text-gray-900 shadow-sm focus:ring-2 focus:ring-orange-600"
                    onChange={(evt) => {
                        setShowSave(true);
                        updateNodeData(id, { step: { ...data.step, emailStep: { ...data.step.emailStep, subject: evt.target.value } } });
                    }}
                    value={data.step.emailStep?.subject}
                />
            </div>

            <div className="flex items-center space-x-2">
                <label className="w-16 text-right text-black">To</label>
                <input
                    className="flex-1 bg-gray-100 rounded-md border-gray-300 p-1.5 text-gray-900 shadow-sm"
                    disabled
                    value={user.user?.email}
                />
            </div>

            <Handle type="target" position={Position.Left} />
        </div>
    );
}

function DataSourceNode({ id, data }: NodeProps<Node<{
    step: Step
    workflowID: string
    supportedTickers: string[]
}>>) {
    const { updateNodeData } = useReactFlow();
    const [allowDrag, setAllowDrag] = useState(true);
    const [showSave, setShowSave] = useState(false);
    const user = useAppSelector((state) => state.user);
    const dispatch = useDispatch();

    return (
        <div className={`space-y-3 w-64 ${!allowDrag ? 'nodrag' : ''}`}>
            <div className="text-black flex items-center justify-center">
                Data Source
                {showSave && <FileIcon
                    onClick={() => {
                        updateStep(user.credentials!.accessToken, data.workflowID, data.step.id!, data.step)
                            .then(() => {
                                dispatch(setNotification({
                                    type: 'success',
                                    message: 'Step saved successfully'
                                }));
                                setShowSave(false);
                            })
                            .catch((reason) => {
                                console.error('Failed to save step', reason);
                                dispatch(setNotification({
                                    type: 'error',
                                    message: 'Failed to save step' + reason
                                }));
                            });
                    }}
                    className="w-4 h-4 cursor-pointer ml-2"
                />}
            </div>

            <div className="flex items-center space-x-2">
                <label className="w-16 text-right text-black">Type</label>
                <select
                    className="flex-1 w-full overflow-clip text-ellipsis rounded-md border-gray-300 p-1.5 text-gray-900 shadow-sm focus:ring-2 focus:ring-orange-600"
                    onChange={(evt) => {
                        setAllowDrag(true);
                        updateNodeData(id, { step: { ...data.step, dataSourceStep: { ...data.step.dataSourceStep, subType: evt.target.value } } });
                        setShowSave(true);
                    }}
                    onPointerDown={() => setAllowDrag(false)}
                    defaultValue={data.step.dataSourceStep?.subType}
                >
                    <option value="data-source-transcript">Earnings Call</option>
                    <option value="data-source-earnings">Earnings Report (10-Q/K)</option>
                    <option value="data-source-usa-executive-order">USA Executive/Presidential Order</option>
                    <option value="data-source-fed-press-release">FED press release</option>
                    <option disabled value="data-source-congressional-trades">Congressional trade</option>
                    <option value="data-source-8k">8-K (all 8-Ks, unfiltered)</option>
                    <option value="data-source-8k-earnings">8-K earnings early release (Item 2.02)</option>
                    <option disabled value="data-source-8k-new-material-agreement">8-K 1.01 new material agreement</option>
                    <option disabled value="data-source-8k-termination-of-material-agreement">8-K 1.02 termination of material agreement</option>
                    <option disabled value="data-source-8k-bankruptcy">8-K 1.03 bankruptcy/receivership</option>
                    <option disabled value="data-source-8k-asset-acquired-disposed">8-K 2.01 asset acquired/disposed</option>
                    <option disabled value="data-source-8k-new-financial-obligation">8-K 2.03 new financial obligation</option>
                    <option disabled value="data-source-8k-material impairment">8-K 2.06 material impairement</option>
                    <option disabled value="data-source-8k-delisting">8-K 3.01 delisting</option>
                    <option disabled value="data-source-8k-auditor-change">8-K 4.01 change of auditor</option>
                    <option disabled value="data-source-8k-unreliable-statements">8-K 4.02 non-reliance on previous report</option>
                    <option disabled value="data-source-8k-bylaws-ethics">8-K 5.03 changes to incorporation, bylaws, fiscal year</option>
                    <option disabled value="data-source-8k-regulation-fd">8-K 7.01 regulation FD</option>
                    <option disabled value="data-source-8k-acquisiton">8-K bankruptcy/receivership</option>

                </select>
            </div>

            <div className="flex items-center space-x-2">
                <label className="w-16 text-right text-black">Ticker</label>
                <select
                    disabled={!(data.step.dataSourceStep?.subType === 'data-source-earnings' || data.step.dataSourceStep?.subType === 'data-source-transcript' || data.step.dataSourceStep?.subType === 'data-source-8k-earnings' || data.step.dataSourceStep?.subType === 'data-source-8k')}
                    className="flex-1 rounded-md border-gray-300 p-1.5 text-gray-900 shadow-sm focus:ring-2 focus:ring-orange-600 disabled:bg-gray-200"
                    onChange={(evt) => {
                        setAllowDrag(true);
                        updateNodeData(id, { step: { ...data.step, dataSourceStep: { ...data.step.dataSourceStep, ticker: evt.target.value } } });
                        setShowSave(true);
                    }}
                    onPointerDown={() => setAllowDrag(false)}
                    defaultValue={data.step.dataSourceStep?.ticker}
                >
                    {data.supportedTickers.map(t => (
                        <option key={t} value={t}>{t}</option>
                    ))}
                </select>
            </div>


            <Handle type="source" position={Position.Right} />
        </div>
    );
}

function LLMNode({ id, data }: NodeProps<Node<{
    workflowID: string,
    step: Step,
    assistants: Assistant[]
}>>) {
    const user = useAppSelector((state) => state.user);
    const dispatch = useDispatch();
    const { updateNodeData } = useReactFlow();
    const [allowDrag, setAllowDrag] = useState(true);
    const [showSave, setShowSave] = useState(false);
    const [showPromptPopup, setShowPromptPopup] = useState(false);
    const [showStrucutedOutputPopup, setShowStructuredOutputPopup] = useState(false);
    return (
        <div>
            {showPromptPopup && <PopupInput onClose={() => setShowPromptPopup(false)} text={data.step.llmStep?.prompt || ''} setText={(value) => {
                setShowSave(true);
                updateNodeData(id, { step: { ...data.step, llmStep: { ...data.step.llmStep, prompt: value } } })
            }} />}
            {/* adding indentation to the below text will make it indented (ugly) in the rendered text area */}
            {showStrucutedOutputPopup && <JSONSchemaInput onClose={() => setShowStructuredOutputPopup(false)} text={data.step.llmStep?.structuredOutput || ''} setText={(value) => {
                setShowSave(true);
                updateNodeData(id, { step: { ...data.step, llmStep: { ...data.step.llmStep, structuredOutput: value } } })
            }} />}

            <div className={`${!allowDrag ? 'nodrag' : ''}`}>
                <div className="flex items-center justify-center text-black mb-2">
                    Natural Language Processing
                    {showSave && <FileIcon onClick={() => {
                        updateStep(user.credentials!.accessToken, data.workflowID, data.step.id!, data.step).then(() => {
                            dispatch(setNotification({
                                type: 'success',
                                message: 'Step saved successfully'
                            }));
                            setShowSave(false);
                        }).catch((reason) => {
                            console.error('Failed to save step', reason);
                            dispatch(setNotification({
                                type: 'error',
                                message: 'Failed to save step' + reason
                            }));
                        })
                    }} className="w-4 h-4 cursor-pointer ml-2" />}

                    {data.step.lastOutput && (
                        <div className="relative group z-50 flex items-center">
                            <ScrollTextIcon onClick={() => {
                                navigator.clipboard.writeText(data.step.lastOutput || '');
                                dispatch(setNotification({
                                    type: 'success',
                                    message: 'Copied output to clipboard'
                                }));
                            }} className="absolute transform w-4 h-4 ml-2 cursor-pointer" />
                            <div className="absolute bottom-full mb-2 hidden group-hover:block w-64 p-2 bg-gray-700 text-white text-sm rounded-lg shadow-lg">
                                {data.step.lastOutput || 'Step has not run yet'}
                            </div>
                        </div>
                    )}
                    {
                        data?.step?.lastError && (
                            <div className="relative group left-5 z-50 flex items-center">
                                <CloudAlertIcon onClick={() => {
                                    navigator.clipboard.writeText(data.step.lastError || '');
                                    dispatch(setNotification({
                                        type: 'success',
                                        message: 'Copied error to clipboard'
                                    }));
                                }} className="absolute transform w-4 h-4 ml-2 cursor-pointer text-red-500" />
                                <div className="absolute bottom-full mb-2 hidden group-hover:block w-64 p-2 bg-gray-700 text-white text-sm rounded-lg shadow-lg">
                                    {'Last Error: ' + data.step.lastError}
                                </div>
                            </div>
                        )
                    }
                </div>
                <div className="flex items-center mb-2">
                    <div className='mr-2 text-black'>Prompt</div>
                    <div className="relative w-full max-w-xs">
                        <input
                            type="text"
                            placeholder="Enter text"
                            onChange={(evt) => {
                                setShowSave(true);
                                updateNodeData(id, { step: { ...data.step, llmStep: { ...data.step.llmStep, prompt: evt.target.value } } })
                            }}
                            value={data.step.llmStep?.prompt}
                            className="block w-full pr-8 truncate rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
                        />
                        <ArrowsPointingOutIcon onClick={() => {
                            setShowPromptPopup(true)
                        }} className="absolute right-3 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-500 cursor-pointer" />
                    </div>
                </div>
                <div className="flex items-center mb-2">
                    <div className='mr-2 text-black'>Model</div>
                    <select
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
                        onChange={(evt) => {
                            const model = evt.target.value
                            setAllowDrag(true);
                            updateNodeData(id, { step: { ...data.step, llmStep: { ...data.step.llmStep, model: model, reasoningEffort: model === 'o3-mini' ? 'low' : null } } });
                            setShowSave(true);
                        }}
                        defaultValue={data.step.llmStep?.model}
                        onPointerDown={(e) => {
                            setAllowDrag(false);
                        }}
                    >
                        <option key="gpt-4o" value="gpt-4o">
                            gpt-4o
                        </option>
                        <option key="gpt-4o-mini" value="gpt-4o-mini">
                            gpt-4o-mini
                        </option>
                        <option key="o3-mini" value="o3-mini">
                            o3-mini
                        </option>
                    </select>
                    {data.step.llmStep?.model === 'o3-mini' && (
                        <select className="ml-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
                            onChange={(evt) => {
                                setAllowDrag(false);
                                updateNodeData(id, { step: { ...data.step, llmStep: { ...data.step.llmStep, reasoningEffort: evt.target.value } } });
                                setShowSave(true);
                            }}
                            value={data.step.llmStep?.reasoningEffort}
                            onPointerDown={() => {
                                setAllowDrag(false);
                            }}
                        >
                            <option value="low">low</option>
                            <option value="medium">medium</option>
                            <option value="high">high</option>
                        </select>
                    )}
                </div>
                <div className="flex items-center mb-2">
                    <div className='mr-2 text-black'>
                        Structured Output
                        <div className="relative group z-50 flex items-center">
                            <InformationCircleIcon className="absolute right-0 transform -translate-y-1/2 w-4 h-4 ml-2 text-gray-500 cursor-pointer" />
                            <div className="absolute bottom-full mb-2 hidden group-hover:block w-64 p-2 bg-gray-700 text-white text-sm rounded-lg shadow-lg">
                                Structured outputs use a JSON schema to enable transforming unstructured data into structured data. The output can be used in decision and other step types. Refer to the OpenAI docs for more info. *Not available when using assistants. Coming Soon*
                            </div>
                        </div>
                    </div>
                    <div className="relative w-full max-w-xs">
                        <input
                            type="text"
                            placeholder="Enter JSON schema"
                            onChange={(evt) => {
                                setShowSave(true);
                                updateNodeData(id, { step: { ...data.step, llmStep: { ...data.step.llmStep, structuredOutput: evt.target.value } } })
                            }}
                            value={data.step.llmStep?.structuredOutput}
                            className="block w-full truncate pr-8 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
                        />
                        <ArrowsPointingOutIcon onClick={() => {
                            setShowStructuredOutputPopup(true)
                        }} className="absolute right-3 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-500 cursor-pointer" />
                    </div>
                </div>

                <div className="flex items-center mb-2">
                    <input
                        type="checkbox"
                        checked={data.step.llmStep?.useSystemPrompt || false}
                        onChange={(evt) => {
                            setShowSave(true);
                            updateNodeData(id, {
                                step: {
                                    ...data.step,
                                    llmStep: {
                                        ...data.step.llmStep,
                                        useSystemPrompt: evt.target.checked
                                    }
                                }
                            })
                        }}
                        className="rounded border-gray-300 text-orange-600 focus:ring-orange-600"
                    />
                    <div className='ml-2 text-black'>Enable system prompt</div>
                </div>

                <Handle type="target" position={Position.Left} />
                <Handle type="source" position={Position.Right} />
            </div>
        </div>

    );
}

function MenuNode({ id, data }: NodeProps<Node<{
    supportedTickers: string[];
    workflow: Workflow;
    setSelectedWorkflow: (w: Workflow) => void
}>>) {
    const dispatch = useDispatch();
    const user = useAppSelector((state) => state.user);

    const addDataSourceStep = () => {

        const dsStep = data.workflow.steps.find(e => e.stepType === 'data-source');
        if (dsStep !== undefined) {
            dispatch(setNotification({
                type: 'info',
                message: 'Workflows can only have 1 data source step'
            }))
            return;
        }

        createStep(user.credentials!.accessToken, data.workflow.id!, {
            order: 1,
            stepType: 'data-source',
            dataSourceStep: {
                subType: 'data-source-earnings',
                ticker: data.supportedTickers[0]
            }
        }).then(() => {
            getWorkflow(user.credentials?.accessToken!, data.workflow.id!).then((workflow) => {
                data.setSelectedWorkflow(workflow);
            }).catch((reason) => {
                dispatch(setNotification({
                    type: 'error',
                    message: reason
                }));
            })
        }).catch((reason) => {
            dispatch(setNotification({
                type: 'error',
                message: `${reason}`
            }));
        })
    }

    const addLLMStep = () => {
        createStep(user.credentials!.accessToken, data.workflow.id!, {
            order: data.workflow.steps.length + 1,
            stepType: 'llm',
            llmStep: {
                model: 'gpt-4o',
                prompt: '',
                useSystemPrompt: true
            }
        }).then(() => {
            getWorkflow(user.credentials?.accessToken!, data.workflow.id!).then((workflow) => {
                data.setSelectedWorkflow(workflow);
            }).catch((reason) => {
                dispatch(setNotification({
                    type: 'error',
                    message: 'Failed to get workflow after adding LLM step'
                }));
            })
        }).catch((reason) => {
            dispatch(setNotification({
                type: 'error',
                message: `${reason}`
            }));
        })
    }

    const addEmailStep = () => {
        const existingStep = data.workflow.steps.find(e => e.stepType === 'email');

        if (existingStep !== undefined) {
            dispatch(setNotification({
                type: 'info',
                message: 'Only 1 email step is allowed per workflow'
            }))
            return
        }

        createStep(user.credentials!.accessToken, data.workflow.id!, {
            order: data.workflow.steps.length + 1,
            stepType: 'email',
            emailStep: {
                subject: `Workflow Execution: ${data.workflow.name}`
            }
        }).then(() => {
            getWorkflow(user.credentials?.accessToken!, data.workflow.id!).then((workflow) => {
                data.setSelectedWorkflow(workflow);
            }).catch((reason) => {
                dispatch(setNotification({
                    type: 'error',
                    message: 'error getting workflow after adding email step'
                }));
            })
        }).catch((reason) => {
            dispatch(setNotification({
                type: 'error',
                message: "" + reason
            }))
        })
    }

    const addDecisionStep = () => {
        createStep(user.credentials!.accessToken, data.workflow.id!, {
            order: data.workflow.steps.length + 1,
            stepType: 'decision',
            decisionStep: {
                isSmartDecisionStep: false,
                smartDecisionStepQuestion: '',
                model: 'gpt-4o',
                targetAttribute: '',
                targetValue: '',
                operator: 'equal',
                attributeType: 'string'
            }
        }).then((stepId) => {
            getWorkflow(user.credentials?.accessToken!, data.workflow.id!).then((workflow) => {
                data.setSelectedWorkflow(workflow);
            }).catch((reason) => {
                dispatch(setNotification({
                    type: 'error',
                    message: 'Failed to get workflow after adding decision step'
                }));
            });
        }).catch((reason) => {
            dispatch(setNotification({
                type: 'error',
                message: `${reason}`
            }));
        });
    }

    return (
        <div className="bg-white shadow-lg rounded-lg p-2 w-50 border border-gray-200">
            <button onClick={addDataSourceStep} className="flex items-center w-full px-3 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 rounded-md">
                <DatabaseIcon className="w-4 h-4 mr-2" /> + Data Source
                <div className="relative group ml-auto">
                    <InformationCircleIcon className="w-4 h-4 ml-2 text-gray-500 cursor-pointer" />
                    <div className="absolute bottom-full mb-2 hidden group-hover:block w-64 p-2 bg-gray-700 text-white text-sm rounded-lg shadow-lg">
                        Data source events are triggered when a new document of that type is released.
                    </div>
                </div>
            </button>
            <button onClick={addLLMStep} className="flex items-center w-full px-3 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 rounded-md">
                <Cpu className="w-4 h-4 mr-2" /> + LLM Step
                <div className="relative group ml-auto">
                    <InformationCircleIcon className="w-4 h-4 ml-2 text-gray-500 cursor-pointer" />
                    <div className="absolute bottom-full mb-2 hidden group-hover:block w-96 p-2 bg-gray-700 text-white text-sm rounded-lg shadow-lg">
                        <p>Process and produce structured or unstructured data.</p>
                        <p className="">All models have access to tools for:</p>
                        <ul className="list-disc list-outside pl-5">
                            <li>Fetching fundamental data, risk/legal disclosures, etc.</li>
                            <li>Computing financial ratios</li>
                            <li>Semantic search of transcripts</li>
                            <li>Web search</li>
                        </ul>
                        <p className="flex"><InformationCircleIcon className="w-6 h-6 mr-1" />LLM steps have a system prompt enabled by default. Disabling it may hinder tool use</p>
                        <p className="flex items-center"><AlertTriangleIcon className="w-4 h-4 mr-1" />Reasoning models can't execute code</p>
                    </div>
                </div>
            </button>
            <button onClick={addEmailStep} className="flex items-center w-full px-3 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 rounded-md">
                <Mail className="w-4 h-4 mr-2" /> + Email Step
                <div className="relative group ml-auto">
                    <InformationCircleIcon className="w-4 h-4 ml-2 text-gray-500 cursor-pointer" />
                    <div className="absolute bottom-full mb-2 hidden group-hover:block w-64 p-2 bg-gray-700 text-white text-sm rounded-lg shadow-lg">
                        Email steps use an LLM to convert the output of the previous step to email compatible format.
                    </div>
                </div>
            </button>
            <button onClick={() => {
                dispatch(setNotification({
                    type: 'info',
                    message: 'Schedule steps are not yet supported'
                }))
            }} className="flex items-center w-full px-3 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 rounded-md">
                <Clock className="w-4 h-4 mr-2 " /> + Schedule Step
                <div className="relative group ml-auto">
                    <InformationCircleIcon className="w-4 h-4 ml-2 text-gray-500 cursor-pointer" />
                    <div className="absolute bottom-full mb-2 hidden group-hover:block w-64 p-2 bg-gray-700 text-white text-sm rounded-lg shadow-lg">
                        Schedule steps happen in regular intervals or at specific times.
                    </div>
                </div>
            </button>

            <button onClick={() => {
                addDecisionStep()
            }} className="flex items-center w-full px-3 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 rounded-md">
                <Diamond className="w-4 h-4 mr-2 " /> + Decision Step
                <div className="relative group ml-auto">
                    <InformationCircleIcon className="w-4 h-4 ml-2 text-gray-500 cursor-pointer" />
                    <div className="absolute bottom-full mb-2 hidden group-hover:block w-64 p-2 bg-gray-700 text-white text-sm rounded-lg shadow-lg">
                        Decision steps act as a logic gate. Allowing execution to continue if the condition is met and stopping it if not.
                    </div>
                </div>
            </button>
        </div>
    );
}

function DecisionStepNode({ id, data }: NodeProps<Node<{
    step: Step,
    workflowID: string
}>>) {
    const { updateNodeData } = useReactFlow();
    const [allowDrag, setAllowDrag] = useState(true);
    const [showSave, setShowSave] = useState(false);
    const user = useAppSelector((state) => state.user);
    const dispatch = useDispatch();
    const [showSmartDecisionPopup, setShowSmartDecisionPopup] = useState(false);

    function parseValue(value: any) {
        if (typeof value === "number") {
            console.log("returning because already a number", value)
            return value
        }; // Already a number, return as-is
        if (typeof value === "string" && value.trim() !== "" && !isNaN(value as any)) {
            console.log("converting to number", value)
            return Number(value);
        }
        return value; // Preserve original value if not a valid number
    }

    return (
        <>
            {showSmartDecisionPopup && (
                <PopupInput
                    onClose={() => {
                        setShowSmartDecisionPopup((prev) => !prev);
                    }}
                    setText={(value) => {
                        setShowSave(true);
                        updateNodeData(id, {
                            step: {
                                ...data.step,
                                decisionStep: {
                                    ...data.step.decisionStep,
                                    smartDecisionStepQuestion: value
                                }
                            }
                        });
                    }}
                    text={data.step.decisionStep?.smartDecisionStepQuestion || ''}
                    placeholder="Enter question for the model"
                />
            )}
            <div className={`space-y-3 w-72 ${!allowDrag ? 'nodrag' : ''}`}>
                <div className="text-black flex items-center justify-center">
                    Decision Step
                    {showSave && <FileIcon
                        onClick={() => {
                            updateStep(user.credentials!.accessToken, data.workflowID, data.step.id!, data.step)
                                .then(() => {
                                    dispatch(setNotification({
                                        type: 'success',
                                        message: 'Step saved successfully'
                                    }));
                                    setShowSave(false);
                                })
                                .catch((reason) => {
                                    console.error('Failed to save step', reason);
                                    dispatch(setNotification({
                                        type: 'error',
                                        message: 'Failed to save step: ' + reason
                                    }));
                                });
                        }}
                        className="w-4 h-4 cursor-pointer ml-2"
                    />}

                    {data?.step?.lastError && (
                        <div className="relative group left-5 z-50 flex items-center">
                            <CloudAlertIcon onClick={() => {
                                navigator.clipboard.writeText(data.step.lastError || '');
                                dispatch(setNotification({
                                    type: 'success',
                                    message: 'Copied error to clipboard'
                                }));
                            }} className="absolute transform w-4 h-4 ml-2 cursor-pointer text-red-500" />
                            <div className="absolute bottom-full mb-2 hidden group-hover:block w-64 p-2 bg-gray-700 text-white text-sm rounded-lg shadow-lg">
                                {'Last Error: ' + data.step.lastError}
                            </div>
                        </div>
                    )}
                </div>

                <div className="flex items-center space-x-2">
                    <label className="w-28 text-right text-black">Smart Decision</label>
                    <input
                        type="checkbox"
                        checked={data.step.decisionStep?.isSmartDecisionStep}
                        onChange={(evt) => {
                            setShowSave(true);
                            updateNodeData(id, {
                                step: {
                                    ...data.step,
                                    decisionStep: {
                                        ...data.step.decisionStep,
                                        isSmartDecisionStep: evt.target.checked
                                    }
                                }
                            });
                        }}
                        className="rounded border-gray-300 text-orange-600 focus:ring-orange-600"
                    />
                </div>

                {data.step.decisionStep?.isSmartDecisionStep && (
                    <>
                        <div className="flex items-center space-x-2">
                            <label className="w-28 text-right text-black">Model</label>
                            <select
                                className="flex-1 rounded-md border-gray-300 p-1.5 text-gray-900 shadow-sm focus:ring-2 focus:ring-orange-600"
                                value={data.step.decisionStep?.model || 'gpt-4o'}
                                onChange={(evt) => {
                                    setAllowDrag(true);
                                    setShowSave(true);
                                    updateNodeData(id, {
                                        step: {
                                            ...data.step,
                                            decisionStep: {
                                                ...data.step.decisionStep,
                                                model: evt.target.value
                                            }
                                        }
                                    });
                                }}
                                onPointerDown={() => setAllowDrag(false)}
                            >
                                <option value="gpt-4o">gpt-4o</option>
                                <option value="gpt-4o-mini">gpt-4o-mini</option>
                            </select>
                        </div>

                        <div className="flex items-center space-x-2">
                            <label className="w-28 text-right text-black">Question</label>
                            <input
                                className="block w-full truncate pr-8 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
                                value={data.step.decisionStep?.smartDecisionStepQuestion || ''}
                                onChange={(evt) => {
                                    setShowSave(true);
                                    updateNodeData(id, {
                                        step: {
                                            ...data.step,
                                            decisionStep: {
                                                ...data.step.decisionStep,
                                                smartDecisionStepQuestion: evt.target.value
                                            }
                                        }
                                    });
                                }}
                                placeholder="Enter question..."
                            />
                            <ArrowsPointingOutIcon onClick={() => {
                                setShowSmartDecisionPopup(true)
                            }} className="absolute right-3 transform w-5 h-5 text-gray-500 cursor-pointer" />
                        </div>
                    </>
                )}

                {!data.step.decisionStep?.isSmartDecisionStep && (
                    <>
                        <div className="flex items-center space-x-2">
                            <label className="w-28 text-right text-black">Target Attribute</label>
                            <input
                                className="flex-1 rounded-md border-gray-300 p-1.5 text-gray-900 shadow-sm focus:ring-2 focus:ring-orange-600"
                                value={data.step.decisionStep?.targetAttribute || ''}
                                onChange={(evt) => {
                                    setShowSave(true);
                                    updateNodeData(id, {
                                        step: {
                                            ...data.step,
                                            decisionStep: {
                                                ...data.step.decisionStep,
                                                targetAttribute: evt.target.value
                                            }
                                        }
                                    });
                                }}
                                placeholder="top level attributes only"
                            />
                        </div>

                        <div className="flex items-center space-x-2">
                            <label className="w-28 text-right text-black">Attribute Type</label>
                            <select
                                className="flex-1 rounded-md border-gray-300 p-1.5 text-gray-900 shadow-sm focus:ring-2 focus:ring-orange-600"
                                value={data.step.decisionStep?.attributeType || 'string'}
                                onChange={(evt) => {
                                    setAllowDrag(true);
                                    setShowSave(true);
                                    updateNodeData(id, {
                                        step: {
                                            ...data.step,
                                            decisionStep: {
                                                ...data.step.decisionStep,
                                                attributeType: evt.target.value
                                            }
                                        }
                                    });
                                }}
                                onPointerDown={() => setAllowDrag(false)}
                            >
                                <option value="string">String</option>
                                <option value="number">Number</option>
                            </select>
                        </div>

                        <div className="flex items-center space-x-2">
                            <label className="w-28 text-right text-black">Operator</label>
                            <select
                                className="flex-1 rounded-md border-gray-300 p-1.5 text-gray-900 shadow-sm focus:ring-2 focus:ring-orange-600"
                                value={data.step.decisionStep?.operator || 'equals'}
                                onChange={(evt) => {
                                    setAllowDrag(true);
                                    setShowSave(true);
                                    updateNodeData(id, {
                                        step: {
                                            ...data.step,
                                            decisionStep: {
                                                ...data.step.decisionStep,
                                                operator: evt.target.value
                                            }
                                        }
                                    });
                                }}
                                onPointerDown={() => setAllowDrag(false)}
                            >
                                <option value="equal">Equals</option>
                                <option value="not_equals">Not equals</option>
                                <option value="greater_than">Greater than</option>
                                <option value="less_than">Less than</option>
                                <option value="greater_than_or_equal">Greater than or equal</option>
                                <option value="less_than_or_equal">Less than or equal</option>
                                <option value="contains">Contains</option>
                            </select>
                        </div>

                        <div className="flex items-center space-x-2">
                            <label className="w-28 text-right text-black">Target Value</label>
                            <input
                                className="flex-1 rounded-md border-gray-300 p-1.5 text-gray-900 shadow-sm focus:ring-2 focus:ring-orange-600"
                                value={data.step.decisionStep?.targetValue}
                                onChange={(evt) => {
                                    setShowSave(true);
                                    updateNodeData(id, {
                                        step: {
                                            ...data.step,
                                            decisionStep: {
                                                ...data.step.decisionStep,
                                                targetValue: parseValue(evt.target.value)
                                            }
                                        }
                                    });
                                }}
                                placeholder="Value to compare against"
                            />
                        </div>
                    </>
                )}

                <Handle type="target" position={Position.Left} id="left" />
                {/* bottom handle should point to terminal node */}
                <Handle type="source" position={Position.Bottom} id="bottom" />
                <Handle type="source" position={Position.Right} id="right" />
            </div>
        </>
    );
}

interface PopupInputProps {
    text: string;
    setText: (value: string) => void;
    onClose: () => void;
    placeholder?: string;
}

function PopupInput({ text, setText, onClose, placeholder }: PopupInputProps) {
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-opacity-50 z-[51]">
            <div className="bg-white p-6 rounded-lg shadow-lg relative">
                <button className="absolute top-2 right-2 text-gray-500 hover:text-gray-700" onClick={onClose}>
                    <X className="w-5 h-5" />
                </button>
                <textarea
                    className="resize p-2 border text-black rounded-md focus:ring-2 focus:ring-blue-400"
                    value={text}
                    placeholder={placeholder}
                    onChange={(e) => setText(e.target.value)}
                    rows={15}
                    cols={50}
                />
            </div>
        </div>
    );
}

function TerminalNode({ id }: NodeProps) {
    return (
        <div className="flex items-center justify-center w-16 h-16 rounded-full bg-red-500 text-white font-bold shadow-lg">
            End
            <Handle type="target" position={Position.Top} id="top" />
        </div>
    );
}

function WorkflowExecutionLogNode({ id, data }: NodeProps<Node<{
    workflow: Workflow;
}>>) {
    const [activeTab, setActiveTab] = useState<'selected' | 'all'>('all');
    const [selectedExecution, setSelectedExecution] = useState<WorkflowExecutionLog | null>(data?.workflow?.executionLogs?.length || 0 > 0 ? data!.workflow!.executionLogs![0] : null);
    const [showingLogDetails, setShowingLogDetails] = useState<{ input: string, output: string } | null>(null);

    const getStatusTextStyling = (status: string) => {
        if (status === "success") {
            return "text-green-600 bg-green-300/[.6]";
        } else if (status === "failed") {
            return "text-red-600 bg-red-300/[.6]";
        } else {
            return "text-yellow-600 bg-yellow-200/[.6]";
        }
    }

    const getStepTitle = (step: Step | undefined) => {
        if (step === undefined) {
            return "Unknown";
        }
        switch (step.stepType) {
            case "data-source":
                switch (step.dataSourceStep?.subType) {
                    case "data-source-transcript":
                        return "Earnings call released";
                    case "data-source-earnings":
                        return "Earnings report released";
                    case "data-source-usa-executive-order":
                        return "Receive executive order";
                    case "data-source-8k-earnings":
                        return "Receive 8-K earnings";
                    default:
                        return "Receive data input";
                }
            case "llm":
                if (step.llmStep?.structuredOutput) {
                    return "Return structured data";
                }
                return "Ask AI a question";
            case "email":
                return "Send an email";
            case "decision":
                return "Logic Gate";
            default:
                return "Unknown";
        }
    }

    const getStatusIcon = (status: StepStatus) => {
        if (status === "success") {
            return <Check className="w-4 h-4 text-green-500" />;
        } else if (status === "failed") {
            return <X className="w-4 h-4 text-red-500" />;
        } else {
            return <Clock className="w-4 h-4 text-yellow-500" />;
        }
    }

    const getStepIcon = (stepType: StepType) => {
        const wh = 'w-6 h-6 '
        switch (stepType) {
            case "data-source":
                return <DatabaseIcon className={wh + "w-4 h-4 text-gray-500"} />;
            case "llm":
                return <Cpu className={wh + "text-gray-500"} />;
            case "email":
                return <Mail className={wh + "text-gray-500"} />;
            case "decision":
                return <Diamond className={wh + "text-gray-500"} />;
            default:
                return <DatabaseIcon className={wh + "text-gray-500"} />;
        }
    }

    const getWorkflowStatus = (workflow: WorkflowExecutionLog): StepStatus => {
        // if any step failed, the workflow is considered failed
        if (workflow.stepLogs.some(log => log.status === 'failed')) {
            return 'failed';
        }
        // if all steps are successful, the workflow is considered successful
        if (workflow.stepLogs.every(log => log.status === 'success')) {
            return 'success';
        }
        // if any step is running, the workflow is considered running
        if (workflow.stepLogs.some(log => log.status === 'executing')) {
            return 'executing';
        }

        return 'idle';
    }

    const getWorkflowFooterText = (workflow: WorkflowExecutionLog): string => {
        if (workflow.stepLogs.some(log => log.status === 'failed')) {
            return 'Run failed on step ' + (workflow.stepLogs.findIndex(log => log.status === 'failed') + 1);
        }
        // if all steps are successful, the workflow is considered successful
        if (workflow.stepLogs.every(log => log.status === 'success')) {
            return 'Run completed successfully';
        }
        // if any step is running, the workflow is considered running
        if (workflow.stepLogs.some(log => log.status === 'executing')) {
            return 'Workflow is executing';
        }

        return 'Workflow is idle';
    }

    useEffect(() => {
        if (data?.workflow?.executionLogs?.length || 0 > 0) {
            setSelectedExecution(data.workflow.executionLogs![0]);
        } else {
            setSelectedExecution(null);
            setActiveTab('all');
        }
    }, [data.workflow]);

    return (
        <>
            {showingLogDetails && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[51]">
                    <div className="bg-white p-6 rounded-lg shadow-lg relative max-w-3xl w-full max-h-[50vh] overflow-y-auto">
                        <button className="absolute top-2 right-2 text-gray-500 hover:text-gray-700" onClick={() => setShowingLogDetails(null)}>
                            <X className="w-5 h-5" />
                        </button>
                        <div className="space-y-4">
                            <div>
                                <h4 className="font-medium text-gray-700">Input:</h4>
                                <textarea
                                    className="w-full h-32 p-2 border rounded-md bg-gray-50 text-black"
                                    value={showingLogDetails.input}
                                    readOnly
                                />
                            </div>
                            <div>
                                <h4 className="font-medium text-gray-700">Output:</h4>
                                <textarea
                                    className="w-full h-32 p-2 border rounded-md bg-gray-50 text-black"
                                    value={showingLogDetails.output}
                                    readOnly
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div className="bg-white shadow-lg rounded-lg p-4 border border-gray-200 w-2xl flex flex-col flex-grow">
                <div className="flex justify-between items-center mb-4">
                    <div className="flex border-b border-gray-200">
                        <button
                            className={`px-4 py-2 text-sm -mb-px ${activeTab === 'all'
                                ? 'text-orange-600 border-b-2 border-orange-500 font-medium'
                                : 'text-gray-500 hover:text-gray-700'}`}
                            onClick={() => setActiveTab('all')}
                        >
                            All Executions
                        </button>
                        <button
                            className={`px-4 py-2 text-sm -mb-px ${activeTab === 'selected'
                                ? 'text-orange-600 border-b-2 border-orange-500 font-medium'
                                : 'text-gray-500 hover:text-gray-700'}`}
                            onClick={() => setActiveTab('selected')}
                        >
                            Selected Execution
                        </button>
                    </div>
                </div>

                <div>
                    {activeTab === 'selected' && selectedExecution ? (
                        <>
                            <ul className="space-y-4">
                                {selectedExecution?.stepLogs?.map((log, index) => {
                                    return (
                                        <li
                                            key={`selected-${log.id}-${log.stepId}`}
                                            className="p-4 bg-white-50 rounded-lg border border-gray-300"
                                        >
                                            <div className="flex items-center space-x-2">
                                                {getStepIcon(log.stepType)}
                                                <span className="text-xl">{getStepTitle(data.workflow.steps.find(e => e.id === log.stepId) || {
                                                    stepType: log.stepType,
                                                    order: 0,
                                                })}</span>
                                            </div>
                                            <div
                                                className="border border-grey rounded p-2 mt-4 w-full flex items-center justify-between cursor-pointer text-gray-600 hover:text-gray-800"
                                                onClick={() => setShowingLogDetails({ input: log.input, output: log.output })}
                                            >
                                                <div className="flex items-center space-x-2">
                                                    <ArrowLeftRight className="w-4 h-4" />
                                                    <span>See all inputs and outputs</span>
                                                </div>
                                            </div>
                                            <div className="flex items-center">
                                                <div className="text-sm mt-2 max-w-fit text-gray-700 bg-gray-200/[.6] rounded p-1 flex items-center space-x-2">
                                                    <Clock className="w-4 h-4 text-gray-500" />
                                                    <span>{log.duration}</span>
                                                </div>
                                                <div className={`text-sm ml-2 mt-2 max-w-fit ${getStatusTextStyling(log.status)} text-green-600 rounded p-1 text-gray-700 flex items-center space-x-2`}>
                                                    {getStatusIcon(log.status)}
                                                    <span>{log.status.charAt(0).toUpperCase() + log.status.slice(1)}</span>
                                                </div>
                                            </div>
                                        </li>
                                    );
                                })}
                            </ul>
                            <div className={`w-full ${getWorkflowStatus(selectedExecution!) === 'failed' ? 'bg-red-100' : getWorkflowStatus(selectedExecution!) === 'success' ? 'bg-green-100' : 'bg-yellow-100'} p-2 rounded-md mt-4`}>
                                <div className="text-left w-full flex items-center">
                                    {getStatusIcon(getWorkflowStatus(selectedExecution!))}
                                    <p className={`text-sm ml-2 ${getWorkflowStatus(selectedExecution!) === 'failed' ? 'text-red-600' : getWorkflowStatus(selectedExecution!) === 'success' ? 'text-green-600' : 'text-yellow-600'
                                        } font-bold`}>{getWorkflowFooterText(selectedExecution!)}</p>
                                </div>
                                <div className="text-left w-full flex items-center">
                                    <Clock className="w-4 h-4 text-gray-500 mr-2" />
                                    {selectedExecution?.duration}
                                </div>
                            </div>
                            {
                                selectedExecution!.isTestExecution && (
                                    <div className={`text-left font-bold w-full mt-2 p-2 flex items-center ${getStatusTextStyling("")}`}>
                                        <FlaskConical className="w-4 h-4 text-yellow-600 mr-2" />
                                        Test Execution
                                    </div>
                                )
                            }
                        </>
                    ) : (
                        <ul className="divide-y divide-gray-200">
                            {data?.workflow?.executionLogs?.map((log, index) => {
                                const executionDate = log.stepLogs.length > 0 ? dayjs(log.stepLogs[0].startTime).format('MMM D, YYYY • h:mm A') : "Unknown";
                                return (
                                    <li onClick={() => {
                                        setSelectedExecution(log);
                                        setActiveTab('selected');
                                    }} key={`all-${id}-${index}`} className="py-3 flex items-center justify-between hover:bg-gray-50 cursor-pointer px-2 rounded-md">
                                        <div className="flex items-center space-x-2">
                                            <div className="flex items-cetner">
                                                <p className="text-sm">{executionDate}</p>
                                                <p className="text-sm ml-2">{data.workflow.name}</p>
                                            </div>
                                        </div>

                                        <div className={`text-sm ${getStatusTextStyling(getWorkflowStatus(log))} ml-2 rounded p-1 text-gray-700 flex items-center space-x-2`}>
                                            {getStatusIcon(getWorkflowStatus(log))}
                                            <span>{getWorkflowStatus(log).charAt(0).toUpperCase() + getWorkflowStatus(log).slice(1)}</span>
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                    )}
                </div>
            </div>
        </>
    );
};

export default WorkflowExecutionLogNode;