import { BeakerIcon, SparklesIcon } from "@heroicons/react/24/solid";
import { X } from "lucide-react";
import { useState } from "react";
import NotificationPopup from "./notification-popup-component";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../store/hooks";
import { getJSONSchemaSuggestion } from "../api/thunks/suggestions";
import { setNotification } from "../store/notifications-reducer";
import { Editor } from "@monaco-editor/react";

const example = `{
    "name": "revenue_guidance_8k",
    "schema": {
        "type": "object",
        "properties": {
            "filing_date": {
                "type": "string",
                "description": "The date when the 8-K was filed."
            },
            "company_name": {
                "type": "string",
                "description": "The name of the company providing the revenue guidance."
            },
            "guidance_amount": {
                "type": "number",
                "description": "The revenue guidance amount provided in the 8-K."
            },
            "guidance_period": {
                "type": "string",
                "description": "The period for which the revenue guidance is applicable."
            },
            "additional_notes": {
                "type": "string",
                "description": "Any additional notes or details regarding the revenue guidance."
            }
        },
        "required": [
            "filing_date",
            "company_name",
            "guidance_amount",
            "guidance_period",
            "additional_notes"
        ],
        "additionalProperties": false
    },
    "strict": true
}`

export interface JSONSchemaInputProps {
    text: string;
    setText: (val: string) => void;
    onClose: () => void
}

export const JSONSchemaInput = ({ text, setText, onClose }: JSONSchemaInputProps) => {
    const [showGenerateInput, setShowGenerateInput] = useState(false);
    const [generateInputPrompt, setGenerateInputPrompt] = useState('');
    const [buttonEnabled, setButtonEnabled] = useState(true);
    const dispatch = useDispatch();
    const user = useAppSelector((state) => state.user);
    const handleClick = () => {
        if (!user.credentials?.accessToken || !generateInputPrompt) {
            return;
        }
        setButtonEnabled(false);
        dispatch(setNotification({
            type: 'info',
            message: 'Generating JSON schema...'
        }));

        getJSONSchemaSuggestion(user.credentials.accessToken, generateInputPrompt)
            .then((schema) => {
                console.log('setting schema to ', schema)
                setText(schema);
                setShowGenerateInput(false);
            })
            .catch((err) => {
                dispatch(setNotification({
                    type: 'error',
                    message: `${err}`
                }))
            }).finally(() => {
                // setGenerateInputPrompt(''); // leaving this commented intentionally because theres probably a decent bit of iteration needed on schemas and loosing your prompt each time you submit would be a pain
                setButtonEnabled(true);
            });
    }

    return (
        <>
            {showGenerateInput && (
                (
                    <div
                        role="dialog"
                        className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white shadow-lg rounded-lg p-4"
                        style={{ width: '375px', zIndex: 1000 }}
                    >
                        <button onClick={() => {
                            setGenerateInputPrompt('');
                            setShowGenerateInput(false);
                        }}>
                            <X className="absolute top-2 right-2 text-gray-500 hover:text-gray-700" />
                        </button>
                        <textarea
                            className="w-full mt-4 h-24 p-2 text-black border rounded-md"
                            value={generateInputPrompt}
                            onChange={(e) => setGenerateInputPrompt(e.target.value)}
                            placeholder="Describe how you want the model to respond, and we'll generate a JSON schema."
                        ></textarea>
                        <div className="px-2 pb-2 flex items-center justify-between">
                            <div className="flex gap-1 items-center font-normal text-sm text-gray-500">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 24 24">
                                    <path fillRule="evenodd" d="M7 3a1 1 0 0 1 1-1h8a1 1 0 1 1 0 2v5.276a1 1 0 0 0 .232.64l3.685 4.423A4.671 4.671 0 0 1 16.33 22H7.671a4.671 4.671 0 0 1-3.588-7.661l.045-.055c.06-.106.139-.201.234-.28l3.406-4.088A1 1 0 0 0 8 9.276V4a1 1 0 0 1-1-1Zm3 1v5.276a3 3 0 0 1-.695 1.92l-1.709 2.05a10.82 10.82 0 0 1 1.4-.027c1.071.048 1.97.254 2.75.433l.219.05c.842.191 1.562.332 2.4.29.624-.03 1.348-.164 2.247-.495l-1.917-2.3A3 3 0 0 1 14 9.276V4h-4Zm7.959 11.113c-1.347.574-2.472.827-3.495.877-1.131.056-2.078-.142-2.941-.338l-.209-.047c-.793-.181-1.525-.348-2.409-.388-.879-.04-1.933.047-3.305.425A2.671 2.671 0 0 0 7.67 20h8.66a2.67 2.67 0 0 0 2.05-4.38l-.421-.507Z" clipRule="evenodd"></path>
                                </svg>
                                Beta. Always verify AI outputs
                            </div>
                            <button
                                disabled={!buttonEnabled}
                                type="button"
                                className="btn-primary disabled:opacity-50"
                                onClick={handleClick}
                            >
                                Create<span className="ml-1">⏎</span>
                            </button>
                        </div>
                    </div>
                ))}
            <div className="fixed inset-0 flex items-center justify-center bg-opacity-50 z-[51]">
                <div className="bg-white p-6 rounded-lg shadow-lg relative">
                    <button onClick={() => setShowGenerateInput(true)} className="absolute top-2 right-8 text-gray-500 hover:text-gray-700">
                        <SparklesIcon className="w-6 h-6 black text-orange-500" />
                    </button>
                    <button className="absolute top-2 right-2 text-gray-500 hover:text-gray-700" onClick={onClose}>
                        <X className="w-5 h-5" />
                    </button>
                    <Editor
                        width={'32vw'}
                        className="w-96 h-96 mt-4"
                        defaultLanguage='json'
                        value={text}
                        onChange={(value) => {
                            setText(value || '');
                        }}
                        theme='light'
                        options={{
                            minimap: { enabled: false },
                            folding: false,
                            wordWrap: 'on',
                        }}
                    />
                </div>
            </div>
        </>
    );
}