import { v4 } from "uuid";
import { Step, Workflow } from "../models/Workflows";
import axios from "axios";

export const getAllWorkflows = async (accessToken: string): Promise<Workflow[]> => {
    try {
        const response = await axios.get(process.env.REACT_APP_API_URL + `/v1/workflows`, {
            headers: {
                'Authorization': accessToken,
                'X-Request-ID': v4()
            }
        });

        if (response.status !== 200) {
            throw new Error("failed to get workflows")
        }

        return response.data as Workflow[];
    }catch (error: any) {
        console.error("error getting workflows", error)
        throw new Error(error?.response?.data?.message || '')
    }
}

export const createWorkflow = async (accessToken: string, name: string, steps: Step[]): Promise<string> => {
    const response = await axios.post(process.env.REACT_APP_API_URL + `/v1/workflows`,
        {
            name,
            steps
        },
        {
            headers: {
                'Authorization': accessToken,
                'X-Request-ID': v4()
            }
        }
    );

    if (response.status !== 201) {
        console.error("failed to create workflow", response.data);
        throw new Error("failed to create workflow")
    }

    return response.data.id as string;
}

export const createStep = async (accessToken: string, workflowID: string, step: Step): Promise<void> => {
    try {
        const response = await axios.post(process.env.REACT_APP_API_URL + `/v1/workflows/${workflowID}/steps`, step, {
            headers: {
                'Authorization': accessToken,
                'X-Request-ID': v4()
            }
        });

        if (response.status !== 201) {
            console.log("failed to create step", response)
            throw new Error(response.data.message)
        }
    } catch (error: any) {
        console.error("error creating step", error)
        throw new Error(error?.response?.data?.message || '')
    }
}

export const updateWorkflow = async (accessToken: string, workflowID: string, name: string, enabled: boolean): Promise<void> => {
    try {
        const response = await axios.put(process.env.REACT_APP_API_URL + `/v1/workflows/${workflowID}`, {
            name,
            enabled
        }, {
            headers: {
                'Authorization': accessToken,
                'X-Request-ID': v4()
            },
        });

        if (response.status !== 204) {
            throw new Error("failed to get workflows")
        }
    } catch (error: any) {
        console.error("error updating workflow", error)
        throw new Error(error?.response?.data?.message || '')
    }
}

export const updateStep = async (accessToken: string, workflowID: string, stepID: string, step: Step): Promise<void> => {
    try {
        const response = await axios.put(process.env.REACT_APP_API_URL + `/v1/workflows/${workflowID}/steps/${stepID}`, step, {
            headers: {
                'Authorization': accessToken,
                'X-Request-ID': v4()
            },
        });

        if (response.status !== 204) {
            console.error("error updating step", response.data);
            throw new Error("failed to update step")
        }
    } catch (error: any) {
        console.error("error updating step", error)
        throw new Error(error?.response?.data?.message || '')
    }
}

export const deleteStep = async (accessToken: string, workflowID: string, stepID: string): Promise<void> => {
    const response = await axios.delete(process.env.REACT_APP_API_URL + `/v1/workflows/${workflowID}/steps/${stepID}`, {
        headers: {
            'Authorization': accessToken,
            'X-Request-ID': v4()
        },
    });

    if (response.status !== 204) {
        throw new Error("failed to get workflows")
    }
}

export const deleteWorkflow = async (accessToken: string, workflowID: string): Promise<void> => {
    const response = await axios.delete(process.env.REACT_APP_API_URL + `/v1/workflows/${workflowID}`, {
        headers: {
            'Authorization': accessToken,
            'X-Request-ID': v4()
        },
    });

    if (response.status !== 204) {
        console.error("failed to delete workflow", response.data)
        throw new Error("failed to get workflows")
    }
}

export const getWorkflow = async (accessToken: string, workflowID: string): Promise<Workflow> => {
    const response = await axios.get(process.env.REACT_APP_API_URL + `/v1/workflows/${workflowID}`, {
        headers: {
            'Authorization': accessToken,
            'X-Request-ID': v4()
        },
    });

    if (response.status !== 200) {
        console.error("failed to get workflow by id", response.data)
        throw new Error("failed to get workflows")
    }

    return response.data as Workflow;
}

export const testWorkflow = async(accessToken: string, workflowID: string): Promise<void> => {
    try {
        const response = await axios.post(process.env.REACT_APP_API_URL + `/v1/workflows/${workflowID}/test`, {}, {
            headers: {
                'Authorization': accessToken,
                'X-Request-ID': v4()
            },
        });

        if (response.status !== 202) {
            console.error("failed to test workflow", response.data)
            throw new Error("failed to get workflows")
        }

    } catch (error: any) {
        console.error("error testing workflow", error)
        throw new Error(error?.response?.data?.message || '')
    }
}
