import { Dialog, DialogPanel } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { navigationRoutes } from "../constants/constants";
import { Share2Icon } from "lucide-react";

export const ChangelogComponent = () => {
    document.title = "Changelog | PocketQuant";
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    const features = [
        {
            title: 'Launch!',
            description: (
                <>
                    <p>This marks the first day in what I hope is a long and fortuitous adventure.</p>
                    <p>I invite you to join me on this journey of mine where I'll be building a product I've wanted for a very long time.</p>
                    <p>I envision PocketQuant as being a place where curious individuals, such as myself,</p>
                    <p>can come to ask questions, run experiments, and deploy code that reacts to the outside world, trading all our greatest ideas.</p>
                    <br />
                    <p>As of today PQ supports using LLMs to ask questions about earnings releases and earnings call transcripts</p>
                    <br />
                    <p>In the near future I plan on adding:</p>
                    <br />
                    <ul className="list-disc list-inside bg-gray-100 p-4 rounded-lg shadow-lg">
                        <li className="py-1">Bespoke datasets (E.g. congressional trades, satellite imagery)</li>
                        <li className="py-1">Function calling (E.g. how does AMZN's price change 3 weeks after its earnings are released)</li>
                        <li className="py-1">Save functions and run them on a schedule or when something happens</li>
                        <li className="py-1">Multiple large language models</li>
                        <li className="py-1">Live earnings calls</li>
                        <li className="py-1">Ability for user to choose datasets in conversation</li>
                    </ul>
                </>
            ),
            date: 'July 30, 2024',
        },
        {
            title: 'Added support for more transcripts',
            description: 'Increased the number of transcripts in the database to 2,153 across 425 companies.',
            date: 'August 6, 2024',
        },
        {
            title: 'Over 3000 transcripts!',
            description: 'PocketQuant now supports asking questions about over 3,300 transcripts spanning 453 companies going as far back as 2009.',
            date: 'August 13, 2024',
        },
        {
            title: 'Small tweaks & changes',
            description:
                <>
                    <ul className="list-disc list-inside p-4 rounded-lg">
                        <li>Implemented some user feedback on confusing flows</li>
                        <li>Gave more flexibility to mobile users for what they can view on the dashboard (select from earnings, transcript, llm conversation)</li>
                        <li>Added a free credit counter</li>
                        <li>Added ticker and logo to conversation column</li>
                    </ul>
                </>,
            date: 'August 20, 2024',
        },
        {
            title: 'Big improvements to citations',
            description:
                <>
                    Citations now include longer more accurate excerpts from the transcripts
                </>,
            date: 'September 17, 2024'
        },
        {
            title: 'Bug fixes & improvements',
            description:
                <>
                    <ul className="list-disc list-inside p-4 rounded-lg">
                        <li>
                            Added a <a href="/try-it">Try It</a> page to let users get a feel for the app
                        </li>
                        <li>
                            Fixed some race conditions and other bugs on the backend
                        </li>
                        <li>
                            Refactored frontend to make it more extensible and cleaner
                        </li>
                    </ul>
                </>,
            date: 'September 22, 2024'
        },
        {
            title: 'New high score on Financebench Top 100',
            description:
                <>
                    <ul className="list-disc list-inside p-4 rounded-lg">
                        <li>
                            Achieved new <a href="pq-v-chatgpt" className="text-blue-500">high score</a> on the FinanceBench Top 100 benchmark of 71%
                        </li>
                        <li>
                            Improved fault tolerance of data engine and re-designed how data ingestion is triggered
                        </li>
                        <li>
                            Improved coverage of earnings reports from ~23,000 to ~28,000 and transcripts from ~3,300 to ~5,700
                        </li>
                        <li>
                            Improved data retrieval mechanisms for the LLMs
                        </li>
                    </ul>
                </>,
            date: 'Oct 14, 2024'
        },
        {
            title: 'New high score on Financebench Top 100',
            description:
                <>
                    <ul className="list-disc list-inside p-4 rounded-lg">
                        <li>
                            Achieved new <a href="pq-v-chatgpt" className="text-blue-500">high score</a> on the FinanceBench Top 100 benchmark of 77%
                        </li>
                        <li>
                            Added features to improve transparency of the LLMs by showing how they got the data that informs their answers
                        </li>
                    </ul>
                </>,
            date: 'Nov 15, 2024'
        },
        {
            title: "Charts & Graphs!",
            description:
                <>
                    <p>
                        Added support for creating and exporting charts and graphs using <b>any</b> of the data that PQ has available. Including:
                        <ul className="list-disc list-inside p-4 rounded-lg">
                            <li>
                                Earnings data
                            </li>
                            <li>
                                Revenue by business segment
                            </li>
                            <li>
                                Revenue by geography
                            </li>
                            <li>
                                Major customers
                            </li>
                            <li>
                                And more
                            </li>
                        </ul>
                        try it <a className="underline text-blue-500" href="/try-it">here</a>
                    </p>
                </>,
            date: 'Nov 27, 2024'
        },
        {
            title: 'New high score on Financebench Top 100',
            description:
                <>
                    <ul className="list-disc list-inside p-4 rounded-lg">
                        <li>
                            Achieved new <a href="pq-v-chatgpt" className="text-blue-500">high score</a> on the FinanceBench Top 100 benchmark of 79%
                        </li>
                    </ul>
                </>,
            date: 'Dec 5, 2024'
        },
        {
            title: 'New high score on Financebench Top 100',
            description:
                <>
                    <ul className="list-disc list-inside p-4 rounded-lg">
                        <li>
                            Achieved new <a href="pq-v-chatgpt" className="text-blue-500">high score</a> on the FinanceBench Top 100 benchmark of 87%
                        </li>
                    </ul>
                </>,
            date: 'Dec 6, 2024'
        },
        {
            title: 'Announcing Artifacts',
            description:
                <>
                    <ul className="list-disc list-inside p-4 rounded-lg">
                        <li>
                            Released the artifacts feature which allows users to save images and code outputs to the cloud so they can view them later even if they delete the conversation they were created in. Users can also edit the code they save and update it.
                        </li>
                    </ul>
                </>,
            date: 'Jan 8, 2025'
        },
        {
            title: 'Workflows!',
            description:
                <>

                    <p className="mt-2">Workflows allow you to intelligently process and respond to company and government events in real time.</p>
                    <p className="mt-2">Workflows allow you to set up a series of steps that process structured and unstructured data from a variety of sources and then make intelligent decisions based on that.</p>
                    <p className="mt-2">Workflows are triggered either by a new document being released or on a schedule (coming soon)</p>
                    <p className="mt-2">Documents can be the release of SEC fillings, USA excutive orders, FED minutes, earnings call transcripts, and more (signup and see the workflows page for a full list)</p>
                    <p className="mt-2">If you have ideas for new data sources, new output steps, or would like a new LLM model supported please email <a className="text-blue-500" href="mailto:tristan@pocket-quant.com">tristan@pocket-quant.com</a> </p>
                </>,
            date: 'Feb 22, 2025'
        },
        {
            title: 'Shareable Conversations',
            description:
                <div className="flex items-center mt-2">
                    Proud of your research? Click the icon on the top right of the conversation to share read only access with friends or colleagues.
                </div>,
            date: 'Feb 23, 2025'
        },
        {
            title: 'Executive Orders as a data source for Workflows',
            description:
                <div className="mt-2">
                    Workflows can now be triggered by Presidential Executive Orders. This allows you to monitor and analyze executive orders as soon as they are released.
                </div>,
            date: 'Feb 26, 2025'
        },
        {
            title: 'Federal Reserve News as a data source for Workflows',
            description:
                <div className="mt-2">
                    Workflows can now be triggered by all Federal Reseve press releases. Including FOMC minutes and decisions, speeches by members, and Congressional testimonies. You can use the schema wizard in the workflows builder to convert this unstructured data into structured data. For example, to extract rate decisions.
                </div>,
            date: 'Feb 28, 2025'
        },
        {
            title: '8-K Earnings Releases as a data source for Workflows',
            description:
                <div className="mt-2">
                    Companies release their earnings first as an 8-K then later as a 10-Q/K.
                    <p className="mt-2">
                        Workflows can now be triggered by SEC form 8-K with an item 2.02 that is deemed to indicate an earnings release. We use a custom NLP process to determine if the 8-K is an earnings release. More triggers based on specific 8-K document types are coming soon.
                    </p>
                    <p className="mt-2">
                        Just like with the FED news releases, you can use the schema wizard in the workflows builder to convert this unstructured data into structured data. 
                    </p>
                    <p className="mt-2">
                        For example, to extract the status of drugs in a BioTech company's pipeline.
                    </p>
                </div>,
            date: 'Mar 4, 2025'
        },
        {
            title: 'Decision Steps',
            description:
                <div className="mt-2">
                    Workflows can now exit early using decision steps.
                    <p className="mt-2">
                        Decision steps execute boolean logic to determine whether or not to finish the workflow. This helps minimize unwanted step executions and helps to save on costs.
                    </p>
                    <p className="mt-2">
                        You can define the boolean logic using plain English or classical operators. E.x. revenue &gt; 1,000,000
                    </p>
                </div>,
            date: 'Mar 11, 2025'
        },
        {
            title: "New models & model selection",
            description:
                <>
                    <p className="mt-2">Added support for OpenAI's o3-mini to conversations and workflows.</p>
                    <p className="mt-2">Conversations can now span multiple comapnies! New dropdowns have been added to select what earnings report or transcript you're viewing</p>
                </>,
            date: 'Mar 24, 2025'
        },
        {
            title: "Added support for all 8k documents",
            description: 
            <>
                Prior to this release only 8-K documents that were determined to be earnings announcements were supported. Now there is an option in the data source node of the workflow builder for all 8-Ks. More filtered options will come in the future.
            </>,
            date: "April 2, 2025"
        }
    ].reverse();

    return (
        <div className="bg-white">
            <header className="absolute inset-x-0 top-0 z-50">
                <nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
                    <div className="flex lg:flex-1">
                        <a href="/" className="-m-1.5 p-1.5">
                            <span className="sr-only">PocketQuant</span>
                            <img className="h-8 w-auto" src="pq-icon-512.png" alt="" />
                        </a>
                    </div>
                    <div className="flex lg:hidden">
                        <button
                            type="button"
                            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                            onClick={() => setMobileMenuOpen(true)}
                        >
                            <span className="sr-only">Open main menu</span>
                            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>
                    <div className="hidden lg:flex lg:gap-x-12">
                        {navigationRoutes.map((item) => (
                            <a key={item.name} href={item.href} className="text-sm font-semibold leading-6 text-gray-900">
                                {item.name}
                            </a>
                        ))}
                    </div>
                    <div className="hidden lg:flex lg:flex-1 lg:justify-end">
                        <button onClick={() => {
                            window.location.href = '/signup';
                        }} className=" py-2 px-4 bg-orange-600 text-white font-bold rounded-lg transition duration-300">
                            Sign Up Free
                        </button>
                    </div>
                </nav>
                <Dialog className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
                    <div className="fixed inset-0 z-50" />
                    <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                        <div className="flex items-center justify-between">
                            <a href="/" className="-m-1.5 p-1.5">
                                <span className="sr-only">PocketQuant</span>
                                <img className="h-8 w-auto" src="pq-icon-512.png" alt="" />
                            </a>
                            <button
                                type="button"
                                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                                onClick={() => setMobileMenuOpen(false)}
                            >
                                <span className="sr-only">Close menu</span>
                                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                        </div>
                        <div className="mt-6 flow-root">
                            <div className="-my-6 divide-y divide-gray-500/10">
                                <div className="space-y-2 py-6">
                                    {navigationRoutes.map((item) => (
                                        <a
                                            key={item.name}
                                            href={item.href}
                                            className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                        >
                                            {item.name}
                                        </a>
                                    ))}
                                </div>
                                <div className="py-6">
                                    <a
                                        href="/login"
                                        className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                    >
                                        Log in
                                    </a>
                                </div>
                                <div className="py-6">
                                    <a
                                        href="/signup"
                                        className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                    >
                                        Signup
                                    </a>
                                </div>
                            </div>
                        </div>
                    </DialogPanel>
                </Dialog>
            </header>

            <div className="relative isolate px-6 pt-14 lg:px-8">
                <div
                    className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
                    aria-hidden="true"
                >
                    <div
                        className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff7f50] to-[#ffa500] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                        style={{
                            clipPath:
                                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                        }}
                    />
                </div>

                <div className="flex flex-col mt-16 items-center space-y-8 px-4 md:px-0">
                    {features.map((feature, index) => (
                        <div key={index} className="relative w-full max-w-3xl flex">
                            <div className="flex-shrink-0 flex flex-col items-center">
                                <div className="bg-orange-500 rounded-full w-6 h-6 flex items-center justify-center text-white font-bold">
                                    {features.length - index}
                                </div>
                                {index < features.length - 1 && (
                                    <div className="flex-grow w-0.5 bg-gray-300" />
                                )}
                            </div>
                            <div className="ml-8 flex-grow">
                                <div className="text-xl font-semibold">{feature.title}</div>
                                <div className="text-gray-600">{feature.description}</div>
                                <div className="text-sm text-gray-400 mt-2">{feature.date}</div>
                            </div>
                        </div>
                    ))}
                </div>

                <div
                    className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
                    aria-hidden="true"
                >
                    <div
                        className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff7f50] to-[#ffa500] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
                        style={{
                            clipPath:
                                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                        }}
                    />
                </div>
            </div>
        </div>
    );
};
